/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SearchResultList } from '../models';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of SearchResults
         * @param {string} [near] Filter SearchResults based on location (eg. Fort Lauderdale, FL, USA)
         * @param {number} [swLat] The southwest latitude (eg. 26.070365)
         * @param {number} [swLng] The southwest longitude (eg. -80.2039691)
         * @param {number} [neLat] The northeast latitude (eg. 26.21186)
         * @param {number} [neLng] The northeast longitude (eg. -80.087165)
         * @param {number} [lat] The latitude (eg. 26.21186)
         * @param {number} [lng] The longitude (eg. -80.087165)
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [startDate] The trip start date for multi day search, formatted YYYY-MM-DD
         * @param {string} [endDate] The trip end date for multi day search, formatted YYYY-MM-DD
         * @param {Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>} [durations] The durations available of boat
         * @param {Array<'captain' | 'no_captain'>} [captainStatuses] The captain statuses
         * @param {number} [minPassengers] The minimum number of passengers
         * @param {boolean} [instantBookable] Return instant bookable only
         * @param {boolean} [exclusive] Return exclusive boats only
         * @param {number} [priceMin] The minimum price in dollar
         * @param {number} [priceMax] The maximum price in dollar
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>} [activities] The boat activities
         * @param {Array<'sail' | 'power'>} [boatTypes] The boat types
         * @param {Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>} [categories] The boat categories
         * @param {Array<'Sea Ray' | 'Yamaha' | '...'>} [manufacturers] The boat manufacturers
         * @param {Array<'air_conditioning' | 'grill' | '...'>} [features] The boat features
         * @param {number} [minResults] Minimum number of results required befor radius expansion (i.e. currently is &lt;40)
         * @param {number} [maxDistance] Max radius (i.e. currently defaults to 50). It overrides if it is &gt; 50
         * @param {number} [zoomLevel] Zoom level (i.e. currently defaults to 11) and this zoom level changes as user engages with map
         * @param {'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low'} [sortBy] Sort SearchResults
         * @param {number} [page] The page number of results
         * @param {number} [perPage] The number per page. It will fallback to 18 if per_page &gt; 18
         * @param {'captain_boat'} [searchMode] By default we don\&#39;t send this param. For now it only uses when search mode is captain_boat that results will include &#x60;boat_captain&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2SearchGet: async (near?: string, swLat?: number, swLng?: number, neLat?: number, neLng?: number, lat?: number, lng?: number, tripDate?: string, startDate?: string, endDate?: string, durations?: Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>, captainStatuses?: Array<'captain' | 'no_captain'>, minPassengers?: number, instantBookable?: boolean, exclusive?: boolean, priceMin?: number, priceMax?: number, lengthMin?: number, lengthMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>, boatTypes?: Array<'sail' | 'power'>, categories?: Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>, manufacturers?: Array<'Sea Ray' | 'Yamaha' | '...'>, features?: Array<'air_conditioning' | 'grill' | '...'>, minResults?: number, maxDistance?: number, zoomLevel?: number, sortBy?: 'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low', page?: number, perPage?: number, searchMode?: 'captain_boat', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (near !== undefined) {
                localVarQueryParameter['near'] = near;
            }

            if (swLat !== undefined) {
                localVarQueryParameter['sw_lat'] = swLat;
            }

            if (swLng !== undefined) {
                localVarQueryParameter['sw_lng'] = swLng;
            }

            if (neLat !== undefined) {
                localVarQueryParameter['ne_lat'] = neLat;
            }

            if (neLng !== undefined) {
                localVarQueryParameter['ne_lng'] = neLng;
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }

            if (tripDate !== undefined) {
                localVarQueryParameter['trip_date'] = tripDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (durations) {
                localVarQueryParameter['durations'] = durations.join(COLLECTION_FORMATS.csv);
            }

            if (captainStatuses) {
                localVarQueryParameter['captain_statuses'] = captainStatuses.join(COLLECTION_FORMATS.csv);
            }

            if (minPassengers !== undefined) {
                localVarQueryParameter['min_passengers'] = minPassengers;
            }

            if (instantBookable !== undefined) {
                localVarQueryParameter['instant_bookable'] = instantBookable;
            }

            if (exclusive !== undefined) {
                localVarQueryParameter['exclusive'] = exclusive;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['price_min'] = priceMin;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['price_max'] = priceMax;
            }

            if (lengthMin !== undefined) {
                localVarQueryParameter['length_min'] = lengthMin;
            }

            if (lengthMax !== undefined) {
                localVarQueryParameter['length_max'] = lengthMax;
            }

            if (activities) {
                localVarQueryParameter['activities'] = activities.join(COLLECTION_FORMATS.csv);
            }

            if (boatTypes) {
                localVarQueryParameter['boat_types'] = boatTypes.join(COLLECTION_FORMATS.csv);
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories.join(COLLECTION_FORMATS.csv);
            }

            if (manufacturers) {
                localVarQueryParameter['manufacturers'] = manufacturers.join(COLLECTION_FORMATS.csv);
            }

            if (features) {
                localVarQueryParameter['features'] = features.join(COLLECTION_FORMATS.csv);
            }

            if (minResults !== undefined) {
                localVarQueryParameter['min_results'] = minResults;
            }

            if (maxDistance !== undefined) {
                localVarQueryParameter['max_distance'] = maxDistance;
            }

            if (zoomLevel !== undefined) {
                localVarQueryParameter['zoom_level'] = zoomLevel;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (searchMode !== undefined) {
                localVarQueryParameter['search_mode'] = searchMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of SearchResults
         * @param {string} [near] Filter SearchResults based on location (eg. Fort Lauderdale, FL, USA)
         * @param {number} [swLat] The southwest latitude (eg. 26.070365)
         * @param {number} [swLng] The southwest longitude (eg. -80.2039691)
         * @param {number} [neLat] The northeast latitude (eg. 26.21186)
         * @param {number} [neLng] The northeast longitude (eg. -80.087165)
         * @param {number} [lat] The latitude (eg. 26.21186)
         * @param {number} [lng] The longitude (eg. -80.087165)
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [startDate] The trip start date for multi day search, formatted YYYY-MM-DD
         * @param {string} [endDate] The trip end date for multi day search, formatted YYYY-MM-DD
         * @param {Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>} [durations] The durations available of boat
         * @param {Array<'captain' | 'no_captain'>} [captainStatuses] The captain statuses
         * @param {number} [minPassengers] The minimum number of passengers
         * @param {boolean} [instantBookable] Return instant bookable only
         * @param {boolean} [exclusive] Return exclusive boats only
         * @param {number} [priceMin] The minimum price in dollar
         * @param {number} [priceMax] The maximum price in dollar
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>} [activities] The boat activities
         * @param {Array<'sail' | 'power'>} [boatTypes] The boat types
         * @param {Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>} [categories] The boat categories
         * @param {Array<'Sea Ray' | 'Yamaha' | '...'>} [manufacturers] The boat manufacturers
         * @param {Array<'air_conditioning' | 'grill' | '...'>} [features] The boat features
         * @param {number} [minResults] Minimum number of results required befor radius expansion (i.e. currently is &lt;40)
         * @param {number} [maxDistance] Max radius (i.e. currently defaults to 50). It overrides if it is &gt; 50
         * @param {number} [zoomLevel] Zoom level (i.e. currently defaults to 11) and this zoom level changes as user engages with map
         * @param {'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low'} [sortBy] Sort SearchResults
         * @param {number} [page] The page number of results
         * @param {number} [perPage] The number per page. It will fallback to 18 if per_page &gt; 18
         * @param {'captain_boat'} [searchMode] By default we don\&#39;t send this param. For now it only uses when search mode is captain_boat that results will include &#x60;boat_captain&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2SearchGet(near?: string, swLat?: number, swLng?: number, neLat?: number, neLng?: number, lat?: number, lng?: number, tripDate?: string, startDate?: string, endDate?: string, durations?: Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>, captainStatuses?: Array<'captain' | 'no_captain'>, minPassengers?: number, instantBookable?: boolean, exclusive?: boolean, priceMin?: number, priceMax?: number, lengthMin?: number, lengthMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>, boatTypes?: Array<'sail' | 'power'>, categories?: Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>, manufacturers?: Array<'Sea Ray' | 'Yamaha' | '...'>, features?: Array<'air_conditioning' | 'grill' | '...'>, minResults?: number, maxDistance?: number, zoomLevel?: number, sortBy?: 'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low', page?: number, perPage?: number, searchMode?: 'captain_boat', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2SearchGet(near, swLat, swLng, neLat, neLng, lat, lng, tripDate, startDate, endDate, durations, captainStatuses, minPassengers, instantBookable, exclusive, priceMin, priceMax, lengthMin, lengthMax, activities, boatTypes, categories, manufacturers, features, minResults, maxDistance, zoomLevel, sortBy, page, perPage, searchMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * Returns a list of SearchResults
         * @param {string} [near] Filter SearchResults based on location (eg. Fort Lauderdale, FL, USA)
         * @param {number} [swLat] The southwest latitude (eg. 26.070365)
         * @param {number} [swLng] The southwest longitude (eg. -80.2039691)
         * @param {number} [neLat] The northeast latitude (eg. 26.21186)
         * @param {number} [neLng] The northeast longitude (eg. -80.087165)
         * @param {number} [lat] The latitude (eg. 26.21186)
         * @param {number} [lng] The longitude (eg. -80.087165)
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [startDate] The trip start date for multi day search, formatted YYYY-MM-DD
         * @param {string} [endDate] The trip end date for multi day search, formatted YYYY-MM-DD
         * @param {Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>} [durations] The durations available of boat
         * @param {Array<'captain' | 'no_captain'>} [captainStatuses] The captain statuses
         * @param {number} [minPassengers] The minimum number of passengers
         * @param {boolean} [instantBookable] Return instant bookable only
         * @param {boolean} [exclusive] Return exclusive boats only
         * @param {number} [priceMin] The minimum price in dollar
         * @param {number} [priceMax] The maximum price in dollar
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>} [activities] The boat activities
         * @param {Array<'sail' | 'power'>} [boatTypes] The boat types
         * @param {Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>} [categories] The boat categories
         * @param {Array<'Sea Ray' | 'Yamaha' | '...'>} [manufacturers] The boat manufacturers
         * @param {Array<'air_conditioning' | 'grill' | '...'>} [features] The boat features
         * @param {number} [minResults] Minimum number of results required befor radius expansion (i.e. currently is &lt;40)
         * @param {number} [maxDistance] Max radius (i.e. currently defaults to 50). It overrides if it is &gt; 50
         * @param {number} [zoomLevel] Zoom level (i.e. currently defaults to 11) and this zoom level changes as user engages with map
         * @param {'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low'} [sortBy] Sort SearchResults
         * @param {number} [page] The page number of results
         * @param {number} [perPage] The number per page. It will fallback to 18 if per_page &gt; 18
         * @param {'captain_boat'} [searchMode] By default we don\&#39;t send this param. For now it only uses when search mode is captain_boat that results will include &#x60;boat_captain&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2SearchGet(near?: string, swLat?: number, swLng?: number, neLat?: number, neLng?: number, lat?: number, lng?: number, tripDate?: string, startDate?: string, endDate?: string, durations?: Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>, captainStatuses?: Array<'captain' | 'no_captain'>, minPassengers?: number, instantBookable?: boolean, exclusive?: boolean, priceMin?: number, priceMax?: number, lengthMin?: number, lengthMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>, boatTypes?: Array<'sail' | 'power'>, categories?: Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>, manufacturers?: Array<'Sea Ray' | 'Yamaha' | '...'>, features?: Array<'air_conditioning' | 'grill' | '...'>, minResults?: number, maxDistance?: number, zoomLevel?: number, sortBy?: 'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low', page?: number, perPage?: number, searchMode?: 'captain_boat', options?: any): AxiosPromise<SearchResultList> {
            return localVarFp.domesticV2SearchGet(near, swLat, swLng, neLat, neLng, lat, lng, tripDate, startDate, endDate, durations, captainStatuses, minPassengers, instantBookable, exclusive, priceMin, priceMax, lengthMin, lengthMax, activities, boatTypes, categories, manufacturers, features, minResults, maxDistance, zoomLevel, sortBy, page, perPage, searchMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
    /**
     * Returns a list of SearchResults
     * @param {string} [near] Filter SearchResults based on location (eg. Fort Lauderdale, FL, USA)
     * @param {number} [swLat] The southwest latitude (eg. 26.070365)
     * @param {number} [swLng] The southwest longitude (eg. -80.2039691)
     * @param {number} [neLat] The northeast latitude (eg. 26.21186)
     * @param {number} [neLng] The northeast longitude (eg. -80.087165)
     * @param {number} [lat] The latitude (eg. 26.21186)
     * @param {number} [lng] The longitude (eg. -80.087165)
     * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
     * @param {string} [startDate] The trip start date for multi day search, formatted YYYY-MM-DD
     * @param {string} [endDate] The trip end date for multi day search, formatted YYYY-MM-DD
     * @param {Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>} [durations] The durations available of boat
     * @param {Array<'captain' | 'no_captain'>} [captainStatuses] The captain statuses
     * @param {number} [minPassengers] The minimum number of passengers
     * @param {boolean} [instantBookable] Return instant bookable only
     * @param {boolean} [exclusive] Return exclusive boats only
     * @param {number} [priceMin] The minimum price in dollar
     * @param {number} [priceMax] The maximum price in dollar
     * @param {number} [lengthMin] The min boat length in feet
     * @param {number} [lengthMax] The max boat length in feet
     * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>} [activities] The boat activities
     * @param {Array<'sail' | 'power'>} [boatTypes] The boat types
     * @param {Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>} [categories] The boat categories
     * @param {Array<'Sea Ray' | 'Yamaha' | '...'>} [manufacturers] The boat manufacturers
     * @param {Array<'air_conditioning' | 'grill' | '...'>} [features] The boat features
     * @param {number} [minResults] Minimum number of results required befor radius expansion (i.e. currently is &lt;40)
     * @param {number} [maxDistance] Max radius (i.e. currently defaults to 50). It overrides if it is &gt; 50
     * @param {number} [zoomLevel] Zoom level (i.e. currently defaults to 11) and this zoom level changes as user engages with map
     * @param {'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low'} [sortBy] Sort SearchResults
     * @param {number} [page] The page number of results
     * @param {number} [perPage] The number per page. It will fallback to 18 if per_page &gt; 18
     * @param {'captain_boat'} [searchMode] By default we don\&#39;t send this param. For now it only uses when search mode is captain_boat that results will include &#x60;boat_captain&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    domesticV2SearchGet(near?: string, swLat?: number, swLng?: number, neLat?: number, neLng?: number, lat?: number, lng?: number, tripDate?: string, startDate?: string, endDate?: string, durations?: Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>, captainStatuses?: Array<'captain' | 'no_captain'>, minPassengers?: number, instantBookable?: boolean, exclusive?: boolean, priceMin?: number, priceMax?: number, lengthMin?: number, lengthMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>, boatTypes?: Array<'sail' | 'power'>, categories?: Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>, manufacturers?: Array<'Sea Ray' | 'Yamaha' | '...'>, features?: Array<'air_conditioning' | 'grill' | '...'>, minResults?: number, maxDistance?: number, zoomLevel?: number, sortBy?: 'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low', page?: number, perPage?: number, searchMode?: 'captain_boat', options?: AxiosRequestConfig): AxiosPromise<SearchResultList>;

}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
    /**
     * Returns a list of SearchResults
     * @param {string} [near] Filter SearchResults based on location (eg. Fort Lauderdale, FL, USA)
     * @param {number} [swLat] The southwest latitude (eg. 26.070365)
     * @param {number} [swLng] The southwest longitude (eg. -80.2039691)
     * @param {number} [neLat] The northeast latitude (eg. 26.21186)
     * @param {number} [neLng] The northeast longitude (eg. -80.087165)
     * @param {number} [lat] The latitude (eg. 26.21186)
     * @param {number} [lng] The longitude (eg. -80.087165)
     * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
     * @param {string} [startDate] The trip start date for multi day search, formatted YYYY-MM-DD
     * @param {string} [endDate] The trip end date for multi day search, formatted YYYY-MM-DD
     * @param {Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>} [durations] The durations available of boat
     * @param {Array<'captain' | 'no_captain'>} [captainStatuses] The captain statuses
     * @param {number} [minPassengers] The minimum number of passengers
     * @param {boolean} [instantBookable] Return instant bookable only
     * @param {boolean} [exclusive] Return exclusive boats only
     * @param {number} [priceMin] The minimum price in dollar
     * @param {number} [priceMax] The maximum price in dollar
     * @param {number} [lengthMin] The min boat length in feet
     * @param {number} [lengthMax] The max boat length in feet
     * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>} [activities] The boat activities
     * @param {Array<'sail' | 'power'>} [boatTypes] The boat types
     * @param {Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>} [categories] The boat categories
     * @param {Array<'Sea Ray' | 'Yamaha' | '...'>} [manufacturers] The boat manufacturers
     * @param {Array<'air_conditioning' | 'grill' | '...'>} [features] The boat features
     * @param {number} [minResults] Minimum number of results required befor radius expansion (i.e. currently is &lt;40)
     * @param {number} [maxDistance] Max radius (i.e. currently defaults to 50). It overrides if it is &gt; 50
     * @param {number} [zoomLevel] Zoom level (i.e. currently defaults to 11) and this zoom level changes as user engages with map
     * @param {'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low'} [sortBy] Sort SearchResults
     * @param {number} [page] The page number of results
     * @param {number} [perPage] The number per page. It will fallback to 18 if per_page &gt; 18
     * @param {'captain_boat'} [searchMode] By default we don\&#39;t send this param. For now it only uses when search mode is captain_boat that results will include &#x60;boat_captain&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public domesticV2SearchGet(near?: string, swLat?: number, swLng?: number, neLat?: number, neLng?: number, lat?: number, lng?: number, tripDate?: string, startDate?: string, endDate?: string, durations?: Array<'2 hours' | '3 hours' | '4 hours' | '6 hours' | '8 hours'>, captainStatuses?: Array<'captain' | 'no_captain'>, minPassengers?: number, instantBookable?: boolean, exclusive?: boolean, priceMin?: number, priceMax?: number, lengthMin?: number, lengthMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising' | '...'>, boatTypes?: Array<'sail' | 'power'>, categories?: Array<'cruiser' | 'sloop' | 'aluminum_fishing' | '...'>, manufacturers?: Array<'Sea Ray' | 'Yamaha' | '...'>, features?: Array<'air_conditioning' | 'grill' | '...'>, minResults?: number, maxDistance?: number, zoomLevel?: number, sortBy?: 'recommended' | 'newest' | 'price_low_to_high' | 'price_high_to_low' | 'length_low_to_high' | 'length_high_to_low', page?: number, perPage?: number, searchMode?: 'captain_boat', options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).domesticV2SearchGet(near, swLat, swLng, neLat, neLng, lat, lng, tripDate, startDate, endDate, durations, captainStatuses, minPassengers, instantBookable, exclusive, priceMin, priceMax, lengthMin, lengthMax, activities, boatTypes, categories, manufacturers, features, minResults, maxDistance, zoomLevel, sortBy, page, perPage, searchMode, options).then((request) => request(this.axios, this.basePath));
    }
}
