import { mediaMMdMin } from 'helpers/breakpoints';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
#popUpPortal {
  position: absolute;
  z-index: 9999;
}

#modalPortal {
  position: absolute;
  z-index: 9999;
  isolation: isolate;
}

#notistackPortal {
  position: absolute;
  z-index: 9999;
}

.snackbar-container {
  top: 54px !important;
  z-index: 5000 !important;

  ${mediaMMdMin} {
    max-width: 600px !important;
    top: 90px !important;
    right: 25px !important;
  }
}
`;

export default GlobalStyles;
