import { atom } from 'jotai';
import { AfterAuthActionType, AfterAuthType, LoginType } from 'auth/constants';

export const loginTypeAtom = atom<LoginType | null>(LoginType.NONE);

export const afterAuthActionsAtom = atom<AfterAuthType[]>([]);

export const writeAfterAuthActionAtom = atom(null, (get, write, value: AfterAuthType) => {
  write(afterAuthActionsAtom, [...get(afterAuthActionsAtom), value]);
});

export const clearAfterAuthActionsAtom = atom(null, (get, write, value: AfterAuthActionType) => {
  write(
    afterAuthActionsAtom,
    get(afterAuthActionsAtom).filter((e) => e.type !== value)
  );
});

export const failedLoginAtom = atom(false);
export const comingFromLogoutAtom = atom(false);
export const logoutRedirectUrlAtom = atom('');
