import Cookies from 'js-cookie';
import { IS_CLIENT } from '../utils';

export const getDefaultAnalyticsTraits = () => {
  if (!IS_CLIENT) return {};

  const gaClientId =
    window.ga && window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : Cookies.get('_ga')?.slice(6);

  return {
    initial_anonymous_id: Cookies.get('initial_anonymous_id'),
    user_public_id: Cookies.get('user_public_id'),
    uuid: Cookies.get('uuid'),
    ga_client_id: gaClientId,
  };
};
