import LogRocket from 'logrocket';
import { useCallback } from 'react';
import { getDefaultAnalyticsTraits } from '../helpers/analytics';

const useRudderStackTrack = () =>
  useCallback((eventName, properties = {}) => {
    // @ts-ignore
    window.rudderanalytics?.track(eventName, {
      ...getDefaultAnalyticsTraits(),
      ...properties,
    });

    LogRocket.track(eventName);
  }, []);

export default useRudderStackTrack;
