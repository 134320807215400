export const sizes = {
  width: {
    XS: {
      min: 300,
      max: 375,
    },
    SM: {
      min: 320,
      max: 640,
    },
    SM_WIDE: 720,
    MD: {
      min: 640,
      max: 960,
    },
    LG: {
      min: 960,
      max: 1140,
    },
    XL: {
      min: 1140,
      max: 1360,
    },
    XXL: {
      min: 1200,
      max: 1400,
    },
    HD: {
      max: 1700,
    },
  },
  height: {
    XS: {
      max: 575,
    },
    SM: {
      max: 680,
    },
  },
};

// TODO remake queries to use these functions
export const getMinQueryWidth = (minSize, unit = 'px') => `(min-width:${minSize + unit})`;
export const getMaxQueryWidth = (maxSize, unit = 'px') => `(max-width:${maxSize + unit})`;
export const getMinWidthQuery = (minSize, unit) => `@media only screen and ${getMinQueryWidth(minSize, unit)}`;
export const getMaxWidthQuery = (maxSize, unit) => `@media only screen and ${getMaxQueryWidth(maxSize, unit)}`;
export const getViewportWidthQuery = (minSize, maxSize, unit) =>
  `@media only screen and ${getMinQueryWidth(minSize, unit)} and ${getMaxQueryWidth(maxSize, unit)}`;

/* media breakpoints */
export const mediaSmViewport = getViewportWidthQuery(sizes.width.SM.min, sizes.width.SM.max);
export const mediaMdViewport = getViewportWidthQuery(sizes.width.MD.min, sizes.width.MD.max);
export const mediaDesktopViewport = getViewportWidthQuery(sizes.width.SM.min, sizes.width.XL.max);
export const mediaLgBViewport = getMinWidthQuery(sizes.width.LG.min);
export const mediaSizeLgViewport = getViewportWidthQuery(sizes.width.LG.min, sizes.width.LG.max);
export const mediaSizeLgDesktopViewport = getViewportWidthQuery(sizes.width.LG.min, 1355);

export const mediaXsMin = `only screen and ${getMinQueryWidth(sizes.width.XS.min)}`;
export const mediaMXsMin = getMinWidthQuery(sizes.width.XS.min);
export const mediaXsMax = `only screen and ${getMaxQueryWidth(sizes.width.XS.max)}`;
export const mediaMXsMax = getMaxWidthQuery(sizes.width.XS.max);

export const mediaSmMin = `only screen and ${getMinQueryWidth(sizes.width.SM.min)}`;
export const mediaMSmMin = getMinWidthQuery(sizes.width.SM.min);
export const mediaSmMax = `only screen and ${getMaxQueryWidth(sizes.width.SM.max)}`;
export const mediaMSmMax = getMaxWidthQuery(sizes.width.SM.max);

export const mediaSmWideMax = `only screen and ${getMaxQueryWidth(sizes.width.SM_WIDE)}`;
export const mediaMSmWideMax = getMaxWidthQuery(sizes.width.SM_WIDE);

export const mediaMdMin = `only screen and ${getMinQueryWidth(sizes.width.MD.min)}`;
export const mediaMMdMin = getMinWidthQuery(sizes.width.MD.min);
export const mediaMdMax = `only screen and ${getMaxQueryWidth(sizes.width.MD.max)}`;
export const mediaMMdMax = getMaxWidthQuery(sizes.width.MD.max);

export const mediaLgMin = `only screen and ${getMinQueryWidth(sizes.width.LG.min)}`;
export const mediaMLgMin = getMinWidthQuery(sizes.width.LG.min);
export const mediaLgMax = `only screen and ${getMaxQueryWidth(sizes.width.LG.max)}`;
export const mediaMLgMax = getMaxWidthQuery(sizes.width.LG.max);

// TODO check where used if it works when fixed to min
export const mediaXlMin = `only screen and ${getMinQueryWidth(sizes.width.XL.min)}`;
export const mediaXlMax = `only screen and ${getMaxQueryWidth(sizes.width.XL.max)}`;

export const mediaMXlMax = getMaxWidthQuery(sizes.width.XL.max);
// TODO rename to min
export const mediaMXxlMax = getMinWidthQuery(sizes.width.XXL.min);

export const mediaHdMax = `only screen and ${getMaxQueryWidth(sizes.width.HD.max)}`;

/* min/max viewport heights */
export const viewportMaxHXs = '576px';
export const viewportMaxHSm = '680px';

/* media breakpoints height */
export const mediaSmHViewport = `only screen and (max-height:${viewportMaxHSm})`;
export const mediaSsHViewport = `only screen and (max-height:${viewportMaxHXs})`;

export const ipadProPortraitBreakpoint = `(min-device-width : 1024px) and (max-device-width : 1024px) and (min-device-height : 1366px) and (max-device-height : 1366px) and (min-width: 1024px) and (max-width: 1024px)`;
export const ipadProLandscapeBreakpoint = `(min-device-width : 1024px) and (max-device-width : 1024px) and (min-device-height : 1366px) and (max-device-height : 1366px) and (min-width: 1366px) and (max-width: 1366px)`;

// Queries for useBreakpoint hook
export const queries = {
  xs: getMinQueryWidth(490),
  sm: getMinQueryWidth(sizes.width.MD.min),
  md: getMinQueryWidth(sizes.width.LG.min),
  lg: getMinQueryWidth(sizes.width.XL.min),
  xl: getMinQueryWidth(sizes.width.XXL.min),
  xxl: getMinQueryWidth(1440),
  xxxl: getMinQueryWidth(2560),
};
