// @ts-nocheck

import Bugsnag from '@bugsnag/js';
import LogRocket from 'logrocket';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import smoothscroll from 'smoothscroll-polyfill';
import { validate as uuidValidate } from 'uuid';
import getConfig from 'next/config';
import { useLocalStorage } from 'beautiful-react-hooks';

import '../src/app/assets/scss/main.scss';

import '../src/app/assets/scss/_rc_slider.scss';
import '../src/app/assets/scss/_toggle_switch.scss';

import 'components/remodal/Remodal.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../src/app/assets/scss/blw_progress_bar_mobile.scss';

import GlobalStyles from 'globalStyles';
import { useRouter } from 'next/router';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ClerkProvider } from '@clerk/nextjs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { BreakpointProvider } from 'components/BreakpointProvider';
import { getDefaultAnalyticsTraits } from 'helpers/analytics';
import { queries } from 'helpers/breakpoints';
import { Provider as JotaiProvider } from 'jotai';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import { trackEvent } from 'utils/tracking/trackEvent';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import useAnalyticsTrack from 'hooks/useAnalyticsTrack';
import boatsetterPaths from 'utils/boatsetterPaths';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import init from '../src/BugSnagger';
import AppProvider from '../src/app/components/AppProvider';
import SEO from '../src/app/components/SEO';
import MetaInfo from '../src/app/components/pdp/v3/MetaInfo';

const SnackbarProvider = dynamic(() => import('components/banners/CustomSnackbarProvider'), {
  ssr: false,
});

require('intersection-observer');

init();

const ErrorBoundary = Bugsnag.getPlugin('react');

const isPdp = (pathname: string): boolean => pathname === '/boats/[id]';

export const createRudderStackPageEvent = (appRouter, retryCount = 1, props = {}) => {
  const defaultAnalyticsTraits = getDefaultAnalyticsTraits();
  // We need to wait for the client id to be defined before we can send the page event
  // We also want to limit the number of retries to 5

  if (retryCount > 5 || defaultAnalyticsTraits.ga_client_id) {
    const pageProps = {
      ...getDefaultAnalyticsTraits(),
      url: window.location.href,
      ...props,
    };
    window.rudderanalytics?.page(appRouter.pathname, document.title, pageProps);
  } else {
    setTimeout(() => {
      // If the client id is not defined, we will retry in 500ms
      createRudderStackPageEvent(appRouter, retryCount + 1, props);
    }, 500);
  }
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const eventIntakeTrack = useAnalyticsTrack();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const appRouter = useRouter();
  const [queryClient] = useState(() => new QueryClient());
  const rudderTrack = useRudderStackTrack();
  const [galacticaSession] = useLocalStorage('galactica_session', '');

  const onPageChange = () => {
    const { referrer } = document || {};

    if (appRouter.pathname !== boatsetterPaths.search) createRudderStackPageEvent(appRouter);
    trackEvent({
      event: 'customSitePageview',
      page: appRouter.pathname,
      referrer,
    });

    rudderTrack('customSitePageview', { page: appRouter.pathname, referrer });
    eventIntakeTrack('UserViewedPage', {
      anonymousId: Cookies.get('uuid') || '',
      sessionId: galacticaSession || '',
      appScreen: appRouter.pathname,
    });
    LogRocket.track('customSitePageview');
  };

  const setInitialAnonId = (tries = 0) => {
    const anonymousUUID = window.rudderanalytics?.getAnonymousId();
    if (tries > 5) {
      console.warn('Failed to set initial_anonymous_id cookie');
      return;
    }

    if (uuidValidate(anonymousUUID)) {
      Cookies.set('initial_anonymous_id', anonymousUUID);
    } else {
      setTimeout(() => {
        setInitialAnonId(tries + 1);
      }, 100);
    }
  };

  // anonymous id is used for A/B testing in marketplace core
  const setAnonymousId = (tries = 0) => {
    const anonymousUUID = window.rudderanalytics?.getAnonymousId();
    if (tries > 5) {
      console.warn('Failed to set initial_anonymous_id cookie');
      return;
    }

    if (uuidValidate(anonymousUUID)) {
      Cookies.set('anonymous_id', anonymousUUID);
    } else {
      setTimeout(() => {
        setAnonymousId(tries + 1);
      }, 100);
    }
  };

  // This implements the default behavior from styled-components v5
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  };

  useEffect(() => {
    const {
      LOG_ROCKET_KEY_MARKETPLACE_SPA = false,
      NODE_ENV = 'development',
      APP_VERSION,
    } = getConfig().publicRuntimeConfig;
    if (LOG_ROCKET_KEY_MARKETPLACE_SPA && NODE_ENV === 'production') {
      LogRocket.init(LOG_ROCKET_KEY_MARKETPLACE_SPA, {
        release: APP_VERSION,
      });
    }

    Bugsnag.addOnError((event, cb) => {
      LogRocket.getSessionURL((sessionURL) => {
        if (sessionURL) {
          event.addMetadata('logRocketSessionURL', {
            sessionURL,
          });
        }
        // always send with no error even if no sessionURL
        cb(null, true);
      });
    });
  }, []);

  useEffect(() => {
    const firstAnonymousId = Cookies.get('initial_anonymous_id');
    if (!firstAnonymousId) {
      setInitialAnonId();
    }
    setAnonymousId();
    onPageChange();
  }, []);

  useEffect(() => {
    appRouter.events.on('routeChangeComplete', onPageChange);

    return () => {
      appRouter.events.off('routeChangeComplete', onPageChange);
    };
  }, [appRouter]);

  const isPdpPage = isPdp(appRouter.pathname);
  let boatId: string | null;
  if (isPdpPage) {
    boatId = appRouter.query.id;
  }

  return (
    <ErrorBoundary>
      <GlobalStyles />
      <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
        <ClerkProvider {...pageProps}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              {!isPdpPage && <SEO />}
              {isPdpPage && <MetaInfo boatId={boatId} />}
              <JotaiProvider>
                <BreakpointProvider queries={queries}>
                  <div id="app">
                    <SnackbarProvider />
                    <AppProvider>
                      <Component {...pageProps} />
                    </AppProvider>
                  </div>
                </BreakpointProvider>
              </JotaiProvider>
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ClerkProvider>
      </StyleSheetManager>
    </ErrorBoundary>
  );
};
export default MyApp;
