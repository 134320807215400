import { ParsedUrlQuery } from 'querystring';

function escapeRegex(string): string {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

const TRACKING_PREFIXES: string[] = ['utm_', 'iterable_'];

export const removeNotTrackingParams = (query: ParsedUrlQuery): Partial<ParsedUrlQuery> =>
  Object.entries(query).reduce((acc, [k, v]) => {
    const pattern = new RegExp(`^(${TRACKING_PREFIXES.map((prefix) => escapeRegex(prefix)).join('|')})`);

    if (pattern.test(k)) {
      acc[k] = v;
    }

    return acc;
  }, {});
