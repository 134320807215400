/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Boat } from '../models';
// @ts-ignore
import { BoatAddOn } from '../models';
// @ts-ignore
import { BoatAvailablePackages } from '../models';
// @ts-ignore
import { BoatExtendedPackage } from '../models';
// @ts-ignore
import { BoatFeatures } from '../models';
// @ts-ignore
import { BoatInsertOrUpdate } from '../models';
// @ts-ignore
import { BoatModel } from '../models';
// @ts-ignore
import { BoatPhoto } from '../models';
// @ts-ignore
import { BoatReviews } from '../models';
// @ts-ignore
import { Captain } from '../models';
// @ts-ignore
import { DomesticV1BoatsIdCaptainsInvitePostRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdActivationRequirementsGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdAddOnsGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdAddOnsIdDeleteRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdCoiPutRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdCoiUploadPost200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdInstantBookPut200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdInstantBookPutRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdInsuranceGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdInsuranceGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdInsurancesGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdPackagesGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdPackagesIdDeleteRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdPhotosIdDeleteRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdSmartPricingGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdSubmitForApprovalPost422Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsBoatIdTemporaryPackageGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdCalendarGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdCalendarGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdCaptainNetworkRateGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdCaptainsGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdDatePricingDetailsGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdDeleteRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdLienholderPutRequest } from '../models';
// @ts-ignore
import { DomesticV2BoatsIdStatsGet200Response } from '../models';
// @ts-ignore
import { DomesticV3BoatsBoatIdSubmitToInsurancePostRequest } from '../models';
// @ts-ignore
import { InsuranceOwnerPolicy } from '../models';
// @ts-ignore
import { TemporaryPackage } from '../models';
/**
 * BoatsApi - axios parameter creator
 * @export
 */
export const BoatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV1BoatsIdCaptainsInvitePost: async (id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV1BoatsIdCaptainsInvitePost', 'id', id)
            const localVarPath = `/domestic/v1/boats/{id}/captains/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get any requirements due to activate the boat
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdActivationRequirementsGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdActivationRequirementsGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/activation_requirements`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all add ons of the boat.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/add_ons`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsIdDelete: async (boatId: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsIdDelete', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/add_ons/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update existing boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {DomesticV2BoatsBoatIdAddOnsIdDeleteRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsIdPatch: async (boatId: string, id: number, boatAddOn?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsIdPatch', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsIdPatch', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/add_ons/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boatAddOn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new boat add on.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdAddOnsGetRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsPost: async (boatId: string, boatAddOn?: DomesticV2BoatsBoatIdAddOnsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdAddOnsPost', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/add_ons`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boatAddOn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiDeleteDelete: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdCoiDeleteDelete', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/coi_delete`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} boatId The boat public_id
         * @param {DomesticV2BoatsBoatIdCoiPutRequest} [cOIFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiPut: async (boatId: string, cOIFile?: DomesticV2BoatsBoatIdCoiPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdCoiPut', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/coi`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cOIFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {any} [coiFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiUploadPost: async (boatId: string, coiFile?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdCoiUploadPost', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/coi_upload`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


            if (coiFile !== undefined) { 
                localVarFormParams.append('coi_file', coiFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update instant book settings
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInstantBookPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInstantBookPut: async (boatId: string, body: DomesticV2BoatsBoatIdInstantBookPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInstantBookPut', 'boatId', boatId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInstantBookPut', 'body', body)
            const localVarPath = `/domestic/v2/boats/{boat_id}/instant_book`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the boat\'s insurance status
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsuranceGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInsuranceGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/insurance`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit insurance questions
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInsuranceGetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsurancePost: async (boatId: string, body: DomesticV2BoatsBoatIdInsuranceGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInsurancePost', 'boatId', boatId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInsurancePost', 'body', body)
            const localVarPath = `/domestic/v2/boats/{boat_id}/insurance`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the boat\'s insurance statuses
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsurancesGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdInsurancesGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/insurances`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List a available boat packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/packages`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more boat packages
         * @param {string} boatId Boat ID
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesIdDelete: async (boatId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesIdDelete', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/packages/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a existing boat package
         * @param {string} boatId Boat ID
         * @param {string} id Package ID
         * @param {DomesticV2BoatsBoatIdPackagesIdDeleteRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesIdPatch: async (boatId: string, id: string, _package?: DomesticV2BoatsBoatIdPackagesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesIdPatch', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesIdPatch', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/packages/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(_package, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save a new boat packages
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdPackagesGetRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesPost: async (boatId: string, _package?: DomesticV2BoatsBoatIdPackagesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPackagesPost', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/packages`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(_package, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a boat photos
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPhotosIdDelete: async (boatId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPhotosIdDelete', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPhotosIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/photos/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a boat photo
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {DomesticV2BoatsBoatIdPhotosIdDeleteRequest} [boatPhoto] To update boat photo sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPhotosIdPatch: async (boatId: string, id: string, boatPhoto?: DomesticV2BoatsBoatIdPhotosIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPhotosIdPatch', 'boatId', boatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdPhotosIdPatch', 'id', id)
            const localVarPath = `/domestic/v2/boats/{boat_id}/photos/{id}`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boatPhoto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch listing reviews 
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdReviewsGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdReviewsGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat-id}/reviews`
                .replace(`{${"boat-id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of similar boats up to 4 boats.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSimilarBoatsGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdSimilarBoatsGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/similar_boats`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the boat\'s smart pricing
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSmartPricingGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdSmartPricingGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/smart_pricing`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit for approval
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSubmitForApprovalPost: async (boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdSubmitForApprovalPost', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/submit_for_approval`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get temporary package details of the boat. It\'s like consolidated package for one input pricing and sync to current two packages.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdTemporaryPackageGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdTemporaryPackageGet', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/temporary_package`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update or create temporary package of the boat. It\'s like consolidated package for one input pricing and sync with current two packages.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdTemporaryPackageGetRequest} [temporaryPackage] Temporary package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdTemporaryPackagePut: async (boatId: string, temporaryPackage?: DomesticV2BoatsBoatIdTemporaryPackageGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV2BoatsBoatIdTemporaryPackagePut', 'boatId', boatId)
            const localVarPath = `/domestic/v2/boats/{boat_id}/temporary_package`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(temporaryPackage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the earnings estimate for the boat
         * @param {string} make The boat maker
         * @param {string} boatModel The boat model
         * @param {string} yearManufactured The year the boat was manufactured
         * @param {string} latitude The latitude of the boat
         * @param {string} longitude The longitude of the boat
         * @param {string} countryCode The code of the country of the boat location. ie \&quot;US\&quot;
         * @param {string} state The state of the boat location. ie FL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsEstimatedEarningsCalculatorGet: async (make: string, boatModel: string, yearManufactured: string, latitude: string, longitude: string, countryCode: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'make' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'make', make)
            // verify required parameter 'boatModel' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'boatModel', boatModel)
            // verify required parameter 'yearManufactured' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'yearManufactured', yearManufactured)
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'longitude', longitude)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'countryCode', countryCode)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('domesticV2BoatsEstimatedEarningsCalculatorGet', 'state', state)
            const localVarPath = `/domestic/v2/boats/estimated_earnings_calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (make !== undefined) {
                localVarQueryParameter['make'] = make;
            }

            if (boatModel !== undefined) {
                localVarQueryParameter['boat_model'] = boatModel;
            }

            if (yearManufactured !== undefined) {
                localVarQueryParameter['year_manufactured'] = yearManufactured;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['country_code'] = countryCode;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat features list
         * @param {number} [length] The boat length
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsFeaturesGet: async (length?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/boats/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (length !== undefined) {
                localVarQueryParameter['length'] = length;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat calendar availalibity
         * @param {string} id Boat Unique ID
         * @param {string} startOn Start date ISO8601 formated (YYYY-MM-DD)
         * @param {string} endOn End date ISO8601 formated (YYYY-MM-DD)
         * @param {string} [bookingId] Booking Unique ID
         * @param {string} [packageType] Indicates the package type selection from the booking form. Required for Buoy Boats. (captained/bareboat)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCalendarGet: async (id: string, startOn: string, endOn: string, bookingId?: string, packageType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCalendarGet', 'id', id)
            // verify required parameter 'startOn' is not null or undefined
            assertParamExists('domesticV2BoatsIdCalendarGet', 'startOn', startOn)
            // verify required parameter 'endOn' is not null or undefined
            assertParamExists('domesticV2BoatsIdCalendarGet', 'endOn', endOn)
            const localVarPath = `/domestic/v2/boats/{id}/calendar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (startOn !== undefined) {
                localVarQueryParameter['start_on'] = startOn;
            }

            if (endOn !== undefined) {
                localVarQueryParameter['end_on'] = endOn;
            }

            if (bookingId !== undefined) {
                localVarQueryParameter['booking_id'] = bookingId;
            }

            if (packageType !== undefined) {
                localVarQueryParameter['package_type'] = packageType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update boat calendar date
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdCalendarGetRequest} [calendar] To update a boat calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCalendarPatch: async (id: string, calendar?: DomesticV2BoatsIdCalendarGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCalendarPatch', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/calendar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Boatsetter captain network rate
         * @param {string} id Boat public ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainNetworkRateGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCaptainNetworkRateGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/captain_network_rate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat captains
         * @param {string} id Boat ID
         * @param {'active deactivated'} [status] The Boat Captain status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsGet: async (id: string, status?: 'active deactivated', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCaptainsGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/captains`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsInvitePost: async (id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCaptainsInvitePost', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/captains/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a captain
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdCaptainsGetRequest} [captain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsPost: async (id: string, captain?: DomesticV2BoatsIdCaptainsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCaptainsPost', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/captains`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(captain, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get eligible captains
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsSearchGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdCaptainsSearchGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/captains/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Boat date price details
         * @param {string} id Boat Unique ID
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDatePricingDetailsGet: async (id: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdDatePricingDetailsGet', 'id', id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('domesticV2BoatsIdDatePricingDetailsGet', 'date', date)
            const localVarPath = `/domestic/v2/boats/{id}/date_pricing_details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine if boat may be deleted
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDeletableGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdDeletableGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/deletable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the boat. Only approved boats with no blocking bookings or incomplete boats are deletable
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdDeleteRequest} [boat] Delete boat params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDelete: async (id: string, boat?: DomesticV2BoatsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat detail
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increments boats page view count
         * @param {string} id The boat public_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdIncrementPageViewPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdIncrementPageViewPost', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/increment_page_view`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdLienholderPutRequest} [lienholderInfo] Creates the boat lienholder information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdLienholderPut: async (id: string, lienholderInfo?: DomesticV2BoatsIdLienholderPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdLienholderPut', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/lienholder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lienholderInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update boat
         * @param {string} id Boat Unique ID
         * @param {BoatInsertOrUpdate} [boat] To update a boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPatch: async (id: string, boat?: BoatInsertOrUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdPatch', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat photos list
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPhotosGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdPhotosGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/photos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save boat photos
         * @param {string} id Boat ID
         * @param {any} photos Boat photos file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPhotosPost: async (id: string, photos: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdPhotosPost', 'id', id)
            // verify required parameter 'photos' is not null or undefined
            assertParamExists('domesticV2BoatsIdPhotosPost', 'photos', photos)
            const localVarPath = `/domestic/v2/boats/{id}/photos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


            if (photos !== undefined) { 
                localVarFormParams.append('photos[]', photos as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User dashboard stats
         * @param {string} id Boat Unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdStatsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BoatsIdStatsGet', 'id', id)
            const localVarPath = `/domestic/v2/boats/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat makes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsMakesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/boats/makes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get boat models list
         * @param {string} make The boat make
         * @param {number} year The boat year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsModelsGet: async (make: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'make' is not null or undefined
            assertParamExists('domesticV2BoatsModelsGet', 'make', make)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('domesticV2BoatsModelsGet', 'year', year)
            const localVarPath = `/domestic/v2/boats/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (make !== undefined) {
                localVarQueryParameter['make'] = make;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new boat
         * @param {BoatInsertOrUpdate} body New Boat Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsPost: async (body: BoatInsertOrUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BoatsPost', 'body', body)
            const localVarPath = `/domestic/v2/boats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the expected pricing
         * @param {string} packageId The package ID
         * @param {number} price The package price in cents
         * @param {'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration
         * @param {boolean} [includeCaptainPrice] This option wil override the package option. include_captain_price: false (I will provide the list), include_captain_price: true (Boatsetter will provide the list)
         * @param {number} [customCaptainPrice] 
         * @param {string} [captainPaymentCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsPriceCalculatorGet: async (packageId: string, price: number, duration: 'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours', includeCaptainPrice?: boolean, customCaptainPrice?: number, captainPaymentCollection?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('domesticV2BoatsPriceCalculatorGet', 'packageId', packageId)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('domesticV2BoatsPriceCalculatorGet', 'price', price)
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('domesticV2BoatsPriceCalculatorGet', 'duration', duration)
            const localVarPath = `/domestic/v2/boats/price_calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (packageId !== undefined) {
                localVarQueryParameter['package_id'] = packageId;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (includeCaptainPrice !== undefined) {
                localVarQueryParameter['include_captain_price'] = includeCaptainPrice;
            }

            if (customCaptainPrice !== undefined) {
                localVarQueryParameter['custom_captain_price'] = customCaptainPrice;
            }

            if (captainPaymentCollection !== undefined) {
                localVarQueryParameter['captain_payment_collection'] = captainPaymentCollection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {'captained' | 'bareboat'} [packageType] The package type
         * @param {string} [make] The boat make
         * @param {Array<string>} [makes] The boat makes
         * @param {number} [passengers] The number of passengers
         * @param {number} [passengerMin] The min number of passengers
         * @param {number} [passengerMax] The max number of passengers
         * @param {number} [ratingMin] The min rating
         * @param {number} [ratingMax] The max rating
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>} [activities] Boat activities
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [category] The boat category
         * @param {'sail' | 'power'} [boatType] The type of boat
         * @param {Array<string>} [features] The boat features
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {number} [priceMin] The min booking price in cents
         * @param {number} [priceMax] The max booking price in cents
         * @param {boolean} [instantBookable] Include instant bookable boats only. Value can be anything, e.g: true
         * @param {number} [lat] Latitude of the center search coordinate. If used, lng is required.
         * @param {number} [lng] Longitude of the center search coordinate. If used, lat is required
         * @param {string} [latLngNe] Comma separate lat,lng. E.g. 38.23983070389973,-122.16784287659897
         * @param {string} [latLngSw] Comma separated lat,lng. E.g., 37.046990106661035,-122.60454941956772
         * @param {number} [distance] The distance in meters from the search center
         * @param {'best_match' | 'price_high_to_low' | 'price_low_to_high'} [sortBy] The sort method.
         * @param {Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>} [durations] The durations available of boat
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        domesticV2BoatsSearchGet: async (perPage?: number, page?: number, packageType?: 'captained' | 'bareboat', make?: string, makes?: Array<string>, passengers?: number, passengerMin?: number, passengerMax?: number, ratingMin?: number, ratingMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>, tripDate?: string, category?: string, boatType?: 'sail' | 'power', features?: Array<string>, lengthMin?: number, lengthMax?: number, priceMin?: number, priceMax?: number, instantBookable?: boolean, lat?: number, lng?: number, latLngNe?: string, latLngSw?: string, distance?: number, sortBy?: 'best_match' | 'price_high_to_low' | 'price_low_to_high', durations?: Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/boats/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (packageType !== undefined) {
                localVarQueryParameter['package_type'] = packageType;
            }

            if (make !== undefined) {
                localVarQueryParameter['make'] = make;
            }

            if (makes) {
                localVarQueryParameter['makes'] = makes.join(COLLECTION_FORMATS.csv);
            }

            if (passengers !== undefined) {
                localVarQueryParameter['passengers'] = passengers;
            }

            if (passengerMin !== undefined) {
                localVarQueryParameter['passenger_min'] = passengerMin;
            }

            if (passengerMax !== undefined) {
                localVarQueryParameter['passenger_max'] = passengerMax;
            }

            if (ratingMin !== undefined) {
                localVarQueryParameter['rating_min'] = ratingMin;
            }

            if (ratingMax !== undefined) {
                localVarQueryParameter['rating_max'] = ratingMax;
            }

            if (activities) {
                localVarQueryParameter['activities'] = activities.join(COLLECTION_FORMATS.csv);
            }

            if (tripDate !== undefined) {
                localVarQueryParameter['trip_date'] = tripDate;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (boatType !== undefined) {
                localVarQueryParameter['boat_type'] = boatType;
            }

            if (features) {
                localVarQueryParameter['features'] = features.join(COLLECTION_FORMATS.csv);
            }

            if (lengthMin !== undefined) {
                localVarQueryParameter['length_min'] = lengthMin;
            }

            if (lengthMax !== undefined) {
                localVarQueryParameter['length_max'] = lengthMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['price_min'] = priceMin;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['price_max'] = priceMax;
            }

            if (instantBookable !== undefined) {
                localVarQueryParameter['instant_bookable'] = instantBookable;
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }

            if (latLngNe !== undefined) {
                localVarQueryParameter['lat_lng_ne'] = latLngNe;
            }

            if (latLngSw !== undefined) {
                localVarQueryParameter['lat_lng_sw'] = latLngSw;
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (durations) {
                localVarQueryParameter['durations'] = durations.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of selectable packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BoatsBoatIdAvailablePackagesGet: async (boatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV3BoatsBoatIdAvailablePackagesGet', 'boatId', boatId)
            const localVarPath = `/domestic/v3/boats/{boat_id}/available_packages`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits a boat to the insurance provider
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BoatsBoatIdSubmitToInsurancePost: async (boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boatId' is not null or undefined
            assertParamExists('domesticV3BoatsBoatIdSubmitToInsurancePost', 'boatId', boatId)
            const localVarPath = `/domestic/v3/boats/{boat_id}/submit_to_insurance`
                .replace(`{${"boat_id"}}`, encodeURIComponent(String(boatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoatsApi - functional programming interface
 * @export
 */
export const BoatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoatsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV1BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV1BoatsIdCaptainsInvitePost(id, invite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get any requirements due to activate the boat
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdActivationRequirementsGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdActivationRequirementsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdActivationRequirementsGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all add ons of the boat.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdAddOnsGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatAddOn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdAddOnsGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdAddOnsIdDelete(boatId: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdAddOnsIdDelete(boatId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update existing boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {DomesticV2BoatsBoatIdAddOnsIdDeleteRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdAddOnsIdPatch(boatId: string, id: number, boatAddOn?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatAddOn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdAddOnsIdPatch(boatId, id, boatAddOn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new boat add on.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdAddOnsGetRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdAddOnsPost(boatId: string, boatAddOn?: DomesticV2BoatsBoatIdAddOnsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatAddOn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdAddOnsPost(boatId, boatAddOn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdCoiDeleteDelete(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdCoiDeleteDelete(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} boatId The boat public_id
         * @param {DomesticV2BoatsBoatIdCoiPutRequest} [cOIFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdCoiPut(boatId: string, cOIFile?: DomesticV2BoatsBoatIdCoiPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdCoiPut(boatId, cOIFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {any} [coiFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdCoiUploadPost(boatId: string, coiFile?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdCoiUploadPost(boatId, coiFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update instant book settings
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInstantBookPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdInstantBookPut(boatId: string, body: DomesticV2BoatsBoatIdInstantBookPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdInstantBookPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdInstantBookPut(boatId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the boat\'s insurance status
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdInsuranceGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdInsuranceGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdInsuranceGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit insurance questions
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInsuranceGetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdInsurancePost(boatId: string, body: DomesticV2BoatsBoatIdInsuranceGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdInsurancePost(boatId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the boat\'s insurance statuses
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdInsurancesGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdInsurancesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdInsurancesGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List a available boat packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPackagesGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatExtendedPackage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPackagesGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more boat packages
         * @param {string} boatId Boat ID
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPackagesIdDelete(boatId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPackagesIdDelete(boatId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a existing boat package
         * @param {string} boatId Boat ID
         * @param {string} id Package ID
         * @param {DomesticV2BoatsBoatIdPackagesIdDeleteRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPackagesIdPatch(boatId: string, id: string, _package?: DomesticV2BoatsBoatIdPackagesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPackagesIdPatch(boatId, id, _package, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save a new boat packages
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdPackagesGetRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPackagesPost(boatId: string, _package?: DomesticV2BoatsBoatIdPackagesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPackagesPost(boatId, _package, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a boat photos
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPhotosIdDelete(boatId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPhotosIdDelete(boatId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a boat photo
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {DomesticV2BoatsBoatIdPhotosIdDeleteRequest} [boatPhoto] To update boat photo sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdPhotosIdPatch(boatId: string, id: string, boatPhoto?: DomesticV2BoatsBoatIdPhotosIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdPhotosIdPatch(boatId, id, boatPhoto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch listing reviews 
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdReviewsGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatReviews>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdReviewsGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of similar boats up to 4 boats.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdSimilarBoatsGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdSimilarBoatsGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the boat\'s smart pricing
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdSmartPricingGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsBoatIdSmartPricingGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdSmartPricingGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit for approval
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdSubmitForApprovalPost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Boat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdSubmitForApprovalPost(boatId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get temporary package details of the boat. It\'s like consolidated package for one input pricing and sync to current two packages.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdTemporaryPackageGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemporaryPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdTemporaryPackageGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update or create temporary package of the boat. It\'s like consolidated package for one input pricing and sync with current two packages.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdTemporaryPackageGetRequest} [temporaryPackage] Temporary package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsBoatIdTemporaryPackagePut(boatId: string, temporaryPackage?: DomesticV2BoatsBoatIdTemporaryPackageGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemporaryPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsBoatIdTemporaryPackagePut(boatId, temporaryPackage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the earnings estimate for the boat
         * @param {string} make The boat maker
         * @param {string} boatModel The boat model
         * @param {string} yearManufactured The year the boat was manufactured
         * @param {string} latitude The latitude of the boat
         * @param {string} longitude The longitude of the boat
         * @param {string} countryCode The code of the country of the boat location. ie \&quot;US\&quot;
         * @param {string} state The state of the boat location. ie FL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsEstimatedEarningsCalculatorGet(make: string, boatModel: string, yearManufactured: string, latitude: string, longitude: string, countryCode: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsEstimatedEarningsCalculatorGet(make, boatModel, yearManufactured, latitude, longitude, countryCode, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat features list
         * @param {number} [length] The boat length
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsFeaturesGet(length?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatFeatures>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsFeaturesGet(length, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat calendar availalibity
         * @param {string} id Boat Unique ID
         * @param {string} startOn Start date ISO8601 formated (YYYY-MM-DD)
         * @param {string} endOn End date ISO8601 formated (YYYY-MM-DD)
         * @param {string} [bookingId] Booking Unique ID
         * @param {string} [packageType] Indicates the package type selection from the booking form. Required for Buoy Boats. (captained/bareboat)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCalendarGet(id: string, startOn: string, endOn: string, bookingId?: string, packageType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsIdCalendarGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCalendarGet(id, startOn, endOn, bookingId, packageType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update boat calendar date
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdCalendarGetRequest} [calendar] To update a boat calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCalendarPatch(id: string, calendar?: DomesticV2BoatsIdCalendarGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCalendarPatch(id, calendar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Boatsetter captain network rate
         * @param {string} id Boat public ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCaptainNetworkRateGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsIdCaptainNetworkRateGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCaptainNetworkRateGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat captains
         * @param {string} id Boat ID
         * @param {'active deactivated'} [status] The Boat Captain status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCaptainsGet(id: string, status?: 'active deactivated', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Captain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCaptainsGet(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCaptainsInvitePost(id, invite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a captain
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdCaptainsGetRequest} [captain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCaptainsPost(id: string, captain?: DomesticV2BoatsIdCaptainsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCaptainsPost(id, captain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get eligible captains
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdCaptainsSearchGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Captain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdCaptainsSearchGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Boat date price details
         * @param {string} id Boat Unique ID
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdDatePricingDetailsGet(id: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsIdDatePricingDetailsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdDatePricingDetailsGet(id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Determine if boat may be deleted
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdDeletableGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdDeletableGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the boat. Only approved boats with no blocking bookings or incomplete boats are deletable
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdDeleteRequest} [boat] Delete boat params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdDelete(id: string, boat?: DomesticV2BoatsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdDelete(id, boat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat detail
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Boat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Increments boats page view count
         * @param {string} id The boat public_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdIncrementPageViewPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdIncrementPageViewPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdLienholderPutRequest} [lienholderInfo] Creates the boat lienholder information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdLienholderPut(id: string, lienholderInfo?: DomesticV2BoatsIdLienholderPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdLienholderPut(id, lienholderInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update boat
         * @param {string} id Boat Unique ID
         * @param {BoatInsertOrUpdate} [boat] To update a boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdPatch(id: string, boat?: BoatInsertOrUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdPatch(id, boat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat photos list
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdPhotosGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatPhoto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdPhotosGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save boat photos
         * @param {string} id Boat ID
         * @param {any} photos Boat photos file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdPhotosPost(id: string, photos: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdPhotosPost(id, photos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User dashboard stats
         * @param {string} id Boat Unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsIdStatsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BoatsIdStatsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsIdStatsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat makes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsMakesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsMakesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get boat models list
         * @param {string} make The boat make
         * @param {number} year The boat year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsModelsGet(make: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsModelsGet(make, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new boat
         * @param {BoatInsertOrUpdate} body New Boat Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsPost(body: BoatInsertOrUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Boat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the expected pricing
         * @param {string} packageId The package ID
         * @param {number} price The package price in cents
         * @param {'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration
         * @param {boolean} [includeCaptainPrice] This option wil override the package option. include_captain_price: false (I will provide the list), include_captain_price: true (Boatsetter will provide the list)
         * @param {number} [customCaptainPrice] 
         * @param {string} [captainPaymentCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BoatsPriceCalculatorGet(packageId: string, price: number, duration: 'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours', includeCaptainPrice?: boolean, customCaptainPrice?: number, captainPaymentCollection?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsPriceCalculatorGet(packageId, price, duration, includeCaptainPrice, customCaptainPrice, captainPaymentCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {'captained' | 'bareboat'} [packageType] The package type
         * @param {string} [make] The boat make
         * @param {Array<string>} [makes] The boat makes
         * @param {number} [passengers] The number of passengers
         * @param {number} [passengerMin] The min number of passengers
         * @param {number} [passengerMax] The max number of passengers
         * @param {number} [ratingMin] The min rating
         * @param {number} [ratingMax] The max rating
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>} [activities] Boat activities
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [category] The boat category
         * @param {'sail' | 'power'} [boatType] The type of boat
         * @param {Array<string>} [features] The boat features
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {number} [priceMin] The min booking price in cents
         * @param {number} [priceMax] The max booking price in cents
         * @param {boolean} [instantBookable] Include instant bookable boats only. Value can be anything, e.g: true
         * @param {number} [lat] Latitude of the center search coordinate. If used, lng is required.
         * @param {number} [lng] Longitude of the center search coordinate. If used, lat is required
         * @param {string} [latLngNe] Comma separate lat,lng. E.g. 38.23983070389973,-122.16784287659897
         * @param {string} [latLngSw] Comma separated lat,lng. E.g., 37.046990106661035,-122.60454941956772
         * @param {number} [distance] The distance in meters from the search center
         * @param {'best_match' | 'price_high_to_low' | 'price_low_to_high'} [sortBy] The sort method.
         * @param {Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>} [durations] The durations available of boat
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async domesticV2BoatsSearchGet(perPage?: number, page?: number, packageType?: 'captained' | 'bareboat', make?: string, makes?: Array<string>, passengers?: number, passengerMin?: number, passengerMax?: number, ratingMin?: number, ratingMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>, tripDate?: string, category?: string, boatType?: 'sail' | 'power', features?: Array<string>, lengthMin?: number, lengthMax?: number, priceMin?: number, priceMax?: number, instantBookable?: boolean, lat?: number, lng?: number, latLngNe?: string, latLngSw?: string, distance?: number, sortBy?: 'best_match' | 'price_high_to_low' | 'price_low_to_high', durations?: Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Boat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BoatsSearchGet(perPage, page, packageType, make, makes, passengers, passengerMin, passengerMax, ratingMin, ratingMax, activities, tripDate, category, boatType, features, lengthMin, lengthMax, priceMin, priceMax, instantBookable, lat, lng, latLngNe, latLngSw, distance, sortBy, durations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of selectable packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV3BoatsBoatIdAvailablePackagesGet(boatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatAvailablePackages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV3BoatsBoatIdAvailablePackagesGet(boatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits a boat to the insurance provider
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV3BoatsBoatIdSubmitToInsurancePost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceOwnerPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV3BoatsBoatIdSubmitToInsurancePost(boatId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoatsApi - factory interface
 * @export
 */
export const BoatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoatsApiFp(configuration)
    return {
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV1BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV1BoatsIdCaptainsInvitePost(id, invite, options).then((request) => request(axios, basePath));
        },
        /**
         * Get any requirements due to activate the boat
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdActivationRequirementsGet(boatId: string, options?: any): AxiosPromise<DomesticV2BoatsBoatIdActivationRequirementsGet200Response> {
            return localVarFp.domesticV2BoatsBoatIdActivationRequirementsGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all add ons of the boat.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsGet(boatId: string, options?: any): AxiosPromise<Array<BoatAddOn>> {
            return localVarFp.domesticV2BoatsBoatIdAddOnsGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsIdDelete(boatId: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdAddOnsIdDelete(boatId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update existing boat add on.
         * @param {string} boatId Boat ID
         * @param {number} id ID
         * @param {DomesticV2BoatsBoatIdAddOnsIdDeleteRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsIdPatch(boatId: string, id: number, boatAddOn?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequest, options?: any): AxiosPromise<BoatAddOn> {
            return localVarFp.domesticV2BoatsBoatIdAddOnsIdPatch(boatId, id, boatAddOn, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new boat add on.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdAddOnsGetRequest} [boatAddOn] Boat add on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdAddOnsPost(boatId: string, boatAddOn?: DomesticV2BoatsBoatIdAddOnsGetRequest, options?: any): AxiosPromise<BoatAddOn> {
            return localVarFp.domesticV2BoatsBoatIdAddOnsPost(boatId, boatAddOn, options).then((request) => request(axios, basePath));
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiDeleteDelete(boatId: string, options?: any): AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response> {
            return localVarFp.domesticV2BoatsBoatIdCoiDeleteDelete(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} boatId The boat public_id
         * @param {DomesticV2BoatsBoatIdCoiPutRequest} [cOIFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiPut(boatId: string, cOIFile?: DomesticV2BoatsBoatIdCoiPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdCoiPut(boatId, cOIFile, options).then((request) => request(axios, basePath));
        },
        /**
         * COI file
         * @param {string} boatId 
         * @param {any} [coiFile] COI file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdCoiUploadPost(boatId: string, coiFile?: any, options?: any): AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response> {
            return localVarFp.domesticV2BoatsBoatIdCoiUploadPost(boatId, coiFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Update instant book settings
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInstantBookPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInstantBookPut(boatId: string, body: DomesticV2BoatsBoatIdInstantBookPutRequest, options?: any): AxiosPromise<DomesticV2BoatsBoatIdInstantBookPut200Response> {
            return localVarFp.domesticV2BoatsBoatIdInstantBookPut(boatId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the boat\'s insurance status
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsuranceGet(boatId: string, options?: any): AxiosPromise<DomesticV2BoatsBoatIdInsuranceGet200Response> {
            return localVarFp.domesticV2BoatsBoatIdInsuranceGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit insurance questions
         * @param {string} boatId 
         * @param {DomesticV2BoatsBoatIdInsuranceGetRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsurancePost(boatId: string, body: DomesticV2BoatsBoatIdInsuranceGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdInsurancePost(boatId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the boat\'s insurance statuses
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdInsurancesGet(boatId: string, options?: any): AxiosPromise<DomesticV2BoatsBoatIdInsurancesGet200Response> {
            return localVarFp.domesticV2BoatsBoatIdInsurancesGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * List a available boat packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesGet(boatId: string, options?: any): AxiosPromise<Array<BoatExtendedPackage>> {
            return localVarFp.domesticV2BoatsBoatIdPackagesGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more boat packages
         * @param {string} boatId Boat ID
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesIdDelete(boatId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdPackagesIdDelete(boatId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a existing boat package
         * @param {string} boatId Boat ID
         * @param {string} id Package ID
         * @param {DomesticV2BoatsBoatIdPackagesIdDeleteRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesIdPatch(boatId: string, id: string, _package?: DomesticV2BoatsBoatIdPackagesIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdPackagesIdPatch(boatId, id, _package, options).then((request) => request(axios, basePath));
        },
        /**
         * Save a new boat packages
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdPackagesGetRequest} [_package] To save a boat package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPackagesPost(boatId: string, _package?: DomesticV2BoatsBoatIdPackagesGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdPackagesPost(boatId, _package, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a boat photos
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPhotosIdDelete(boatId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdPhotosIdDelete(boatId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a boat photo
         * @param {string} boatId Boat ID
         * @param {string} id Boat Photo ID
         * @param {DomesticV2BoatsBoatIdPhotosIdDeleteRequest} [boatPhoto] To update boat photo sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdPhotosIdPatch(boatId: string, id: string, boatPhoto?: DomesticV2BoatsBoatIdPhotosIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsBoatIdPhotosIdPatch(boatId, id, boatPhoto, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch listing reviews 
         * @param {string} boatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdReviewsGet(boatId: string, options?: any): AxiosPromise<Array<BoatReviews>> {
            return localVarFp.domesticV2BoatsBoatIdReviewsGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of similar boats up to 4 boats.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSimilarBoatsGet(boatId: string, options?: any): AxiosPromise<object> {
            return localVarFp.domesticV2BoatsBoatIdSimilarBoatsGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the boat\'s smart pricing
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSmartPricingGet(boatId: string, options?: any): AxiosPromise<DomesticV2BoatsBoatIdSmartPricingGet200Response> {
            return localVarFp.domesticV2BoatsBoatIdSmartPricingGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit for approval
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdSubmitForApprovalPost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: any): AxiosPromise<Boat> {
            return localVarFp.domesticV2BoatsBoatIdSubmitForApprovalPost(boatId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get temporary package details of the boat. It\'s like consolidated package for one input pricing and sync to current two packages.
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdTemporaryPackageGet(boatId: string, options?: any): AxiosPromise<TemporaryPackage> {
            return localVarFp.domesticV2BoatsBoatIdTemporaryPackageGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update or create temporary package of the boat. It\'s like consolidated package for one input pricing and sync with current two packages.
         * @param {string} boatId Boat ID
         * @param {DomesticV2BoatsBoatIdTemporaryPackageGetRequest} [temporaryPackage] Temporary package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsBoatIdTemporaryPackagePut(boatId: string, temporaryPackage?: DomesticV2BoatsBoatIdTemporaryPackageGetRequest, options?: any): AxiosPromise<TemporaryPackage> {
            return localVarFp.domesticV2BoatsBoatIdTemporaryPackagePut(boatId, temporaryPackage, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the earnings estimate for the boat
         * @param {string} make The boat maker
         * @param {string} boatModel The boat model
         * @param {string} yearManufactured The year the boat was manufactured
         * @param {string} latitude The latitude of the boat
         * @param {string} longitude The longitude of the boat
         * @param {string} countryCode The code of the country of the boat location. ie \&quot;US\&quot;
         * @param {string} state The state of the boat location. ie FL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsEstimatedEarningsCalculatorGet(make: string, boatModel: string, yearManufactured: string, latitude: string, longitude: string, countryCode: string, state: string, options?: any): AxiosPromise<object> {
            return localVarFp.domesticV2BoatsEstimatedEarningsCalculatorGet(make, boatModel, yearManufactured, latitude, longitude, countryCode, state, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat features list
         * @param {number} [length] The boat length
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsFeaturesGet(length?: number, options?: any): AxiosPromise<Array<BoatFeatures>> {
            return localVarFp.domesticV2BoatsFeaturesGet(length, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat calendar availalibity
         * @param {string} id Boat Unique ID
         * @param {string} startOn Start date ISO8601 formated (YYYY-MM-DD)
         * @param {string} endOn End date ISO8601 formated (YYYY-MM-DD)
         * @param {string} [bookingId] Booking Unique ID
         * @param {string} [packageType] Indicates the package type selection from the booking form. Required for Buoy Boats. (captained/bareboat)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCalendarGet(id: string, startOn: string, endOn: string, bookingId?: string, packageType?: string, options?: any): AxiosPromise<DomesticV2BoatsIdCalendarGet200Response> {
            return localVarFp.domesticV2BoatsIdCalendarGet(id, startOn, endOn, bookingId, packageType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update boat calendar date
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdCalendarGetRequest} [calendar] To update a boat calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCalendarPatch(id: string, calendar?: DomesticV2BoatsIdCalendarGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdCalendarPatch(id, calendar, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Boatsetter captain network rate
         * @param {string} id Boat public ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainNetworkRateGet(id: string, options?: any): AxiosPromise<DomesticV2BoatsIdCaptainNetworkRateGet200Response> {
            return localVarFp.domesticV2BoatsIdCaptainNetworkRateGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat captains
         * @param {string} id Boat ID
         * @param {'active deactivated'} [status] The Boat Captain status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsGet(id: string, status?: 'active deactivated', options?: any): AxiosPromise<Array<Captain>> {
            return localVarFp.domesticV2BoatsIdCaptainsGet(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a captain invitation
         * @param {string} id Boat ID
         * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdCaptainsInvitePost(id, invite, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a captain
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdCaptainsGetRequest} [captain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsPost(id: string, captain?: DomesticV2BoatsIdCaptainsGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdCaptainsPost(id, captain, options).then((request) => request(axios, basePath));
        },
        /**
         * Get eligible captains
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdCaptainsSearchGet(id: string, options?: any): AxiosPromise<Array<Captain>> {
            return localVarFp.domesticV2BoatsIdCaptainsSearchGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Boat date price details
         * @param {string} id Boat Unique ID
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDatePricingDetailsGet(id: string, date: string, options?: any): AxiosPromise<DomesticV2BoatsIdDatePricingDetailsGet200Response> {
            return localVarFp.domesticV2BoatsIdDatePricingDetailsGet(id, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine if boat may be deleted
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDeletableGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdDeletableGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the boat. Only approved boats with no blocking bookings or incomplete boats are deletable
         * @param {string} id Boat Unique ID
         * @param {DomesticV2BoatsIdDeleteRequest} [boat] Delete boat params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdDelete(id: string, boat?: DomesticV2BoatsIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdDelete(id, boat, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat detail
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdGet(id: string, options?: any): AxiosPromise<Boat> {
            return localVarFp.domesticV2BoatsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Increments boats page view count
         * @param {string} id The boat public_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdIncrementPageViewPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdIncrementPageViewPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Boat ID
         * @param {DomesticV2BoatsIdLienholderPutRequest} [lienholderInfo] Creates the boat lienholder information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdLienholderPut(id: string, lienholderInfo?: DomesticV2BoatsIdLienholderPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdLienholderPut(id, lienholderInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Update boat
         * @param {string} id Boat Unique ID
         * @param {BoatInsertOrUpdate} [boat] To update a boat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPatch(id: string, boat?: BoatInsertOrUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdPatch(id, boat, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat photos list
         * @param {string} id Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPhotosGet(id: string, options?: any): AxiosPromise<Array<BoatPhoto>> {
            return localVarFp.domesticV2BoatsIdPhotosGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Save boat photos
         * @param {string} id Boat ID
         * @param {any} photos Boat photos file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdPhotosPost(id: string, photos: any, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BoatsIdPhotosPost(id, photos, options).then((request) => request(axios, basePath));
        },
        /**
         * User dashboard stats
         * @param {string} id Boat Unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsIdStatsGet(id: string, options?: any): AxiosPromise<DomesticV2BoatsIdStatsGet200Response> {
            return localVarFp.domesticV2BoatsIdStatsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat makes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsMakesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.domesticV2BoatsMakesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get boat models list
         * @param {string} make The boat make
         * @param {number} year The boat year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsModelsGet(make: string, year: number, options?: any): AxiosPromise<Array<BoatModel>> {
            return localVarFp.domesticV2BoatsModelsGet(make, year, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new boat
         * @param {BoatInsertOrUpdate} body New Boat Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsPost(body: BoatInsertOrUpdate, options?: any): AxiosPromise<Boat> {
            return localVarFp.domesticV2BoatsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the expected pricing
         * @param {string} packageId The package ID
         * @param {number} price The package price in cents
         * @param {'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration
         * @param {boolean} [includeCaptainPrice] This option wil override the package option. include_captain_price: false (I will provide the list), include_captain_price: true (Boatsetter will provide the list)
         * @param {number} [customCaptainPrice] 
         * @param {string} [captainPaymentCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BoatsPriceCalculatorGet(packageId: string, price: number, duration: 'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours', includeCaptainPrice?: boolean, customCaptainPrice?: number, captainPaymentCollection?: string, options?: any): AxiosPromise<object> {
            return localVarFp.domesticV2BoatsPriceCalculatorGet(packageId, price, duration, includeCaptainPrice, customCaptainPrice, captainPaymentCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * Search boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {'captained' | 'bareboat'} [packageType] The package type
         * @param {string} [make] The boat make
         * @param {Array<string>} [makes] The boat makes
         * @param {number} [passengers] The number of passengers
         * @param {number} [passengerMin] The min number of passengers
         * @param {number} [passengerMax] The max number of passengers
         * @param {number} [ratingMin] The min rating
         * @param {number} [ratingMax] The max rating
         * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>} [activities] Boat activities
         * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
         * @param {string} [category] The boat category
         * @param {'sail' | 'power'} [boatType] The type of boat
         * @param {Array<string>} [features] The boat features
         * @param {number} [lengthMin] The min boat length in feet
         * @param {number} [lengthMax] The max boat length in feet
         * @param {number} [priceMin] The min booking price in cents
         * @param {number} [priceMax] The max booking price in cents
         * @param {boolean} [instantBookable] Include instant bookable boats only. Value can be anything, e.g: true
         * @param {number} [lat] Latitude of the center search coordinate. If used, lng is required.
         * @param {number} [lng] Longitude of the center search coordinate. If used, lat is required
         * @param {string} [latLngNe] Comma separate lat,lng. E.g. 38.23983070389973,-122.16784287659897
         * @param {string} [latLngSw] Comma separated lat,lng. E.g., 37.046990106661035,-122.60454941956772
         * @param {number} [distance] The distance in meters from the search center
         * @param {'best_match' | 'price_high_to_low' | 'price_low_to_high'} [sortBy] The sort method.
         * @param {Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>} [durations] The durations available of boat
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        domesticV2BoatsSearchGet(perPage?: number, page?: number, packageType?: 'captained' | 'bareboat', make?: string, makes?: Array<string>, passengers?: number, passengerMin?: number, passengerMax?: number, ratingMin?: number, ratingMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>, tripDate?: string, category?: string, boatType?: 'sail' | 'power', features?: Array<string>, lengthMin?: number, lengthMax?: number, priceMin?: number, priceMax?: number, instantBookable?: boolean, lat?: number, lng?: number, latLngNe?: string, latLngSw?: string, distance?: number, sortBy?: 'best_match' | 'price_high_to_low' | 'price_low_to_high', durations?: Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>, options?: any): AxiosPromise<Array<Boat>> {
            return localVarFp.domesticV2BoatsSearchGet(perPage, page, packageType, make, makes, passengers, passengerMin, passengerMax, ratingMin, ratingMax, activities, tripDate, category, boatType, features, lengthMin, lengthMax, priceMin, priceMax, instantBookable, lat, lng, latLngNe, latLngSw, distance, sortBy, durations, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of selectable packages
         * @param {string} boatId Boat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BoatsBoatIdAvailablePackagesGet(boatId: string, options?: any): AxiosPromise<BoatAvailablePackages> {
            return localVarFp.domesticV3BoatsBoatIdAvailablePackagesGet(boatId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits a boat to the insurance provider
         * @param {string} boatId 
         * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BoatsBoatIdSubmitToInsurancePost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: any): AxiosPromise<InsuranceOwnerPolicy> {
            return localVarFp.domesticV3BoatsBoatIdSubmitToInsurancePost(boatId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoatsApi - interface
 * @export
 * @interface BoatsApi
 */
export interface BoatsApiInterface {
    /**
     * Create a captain invitation
     * @param {string} id Boat ID
     * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV1BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get any requirements due to activate the boat
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdActivationRequirementsGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdActivationRequirementsGet200Response>;

    /**
     * List all add ons of the boat.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdAddOnsGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<Array<BoatAddOn>>;

    /**
     * Delete boat add on.
     * @param {string} boatId Boat ID
     * @param {number} id ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdAddOnsIdDelete(boatId: string, id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update existing boat add on.
     * @param {string} boatId Boat ID
     * @param {number} id ID
     * @param {DomesticV2BoatsBoatIdAddOnsIdDeleteRequest} [boatAddOn] Boat add on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdAddOnsIdPatch(boatId: string, id: number, boatAddOn?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<BoatAddOn>;

    /**
     * Create a new boat add on.
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdAddOnsGetRequest} [boatAddOn] Boat add on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdAddOnsPost(boatId: string, boatAddOn?: DomesticV2BoatsBoatIdAddOnsGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatAddOn>;

    /**
     * COI file
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdCoiDeleteDelete(boatId: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response>;

    /**
     * 
     * @param {string} boatId The boat public_id
     * @param {DomesticV2BoatsBoatIdCoiPutRequest} [cOIFile] COI file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdCoiPut(boatId: string, cOIFile?: DomesticV2BoatsBoatIdCoiPutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * COI file
     * @param {string} boatId 
     * @param {any} [coiFile] COI file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdCoiUploadPost(boatId: string, coiFile?: any, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdCoiUploadPost200Response>;

    /**
     * Update instant book settings
     * @param {string} boatId 
     * @param {DomesticV2BoatsBoatIdInstantBookPutRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdInstantBookPut(boatId: string, body: DomesticV2BoatsBoatIdInstantBookPutRequest, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdInstantBookPut200Response>;

    /**
     * Get the boat\'s insurance status
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdInsuranceGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdInsuranceGet200Response>;

    /**
     * Submit insurance questions
     * @param {string} boatId 
     * @param {DomesticV2BoatsBoatIdInsuranceGetRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdInsurancePost(boatId: string, body: DomesticV2BoatsBoatIdInsuranceGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the boat\'s insurance statuses
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdInsurancesGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdInsurancesGet200Response>;

    /**
     * List a available boat packages
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPackagesGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<Array<BoatExtendedPackage>>;

    /**
     * Delete one or more boat packages
     * @param {string} boatId Boat ID
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPackagesIdDelete(boatId: string, id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a existing boat package
     * @param {string} boatId Boat ID
     * @param {string} id Package ID
     * @param {DomesticV2BoatsBoatIdPackagesIdDeleteRequest} [_package] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPackagesIdPatch(boatId: string, id: string, _package?: DomesticV2BoatsBoatIdPackagesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Save a new boat packages
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdPackagesGetRequest} [_package] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPackagesPost(boatId: string, _package?: DomesticV2BoatsBoatIdPackagesGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete a boat photos
     * @param {string} boatId Boat ID
     * @param {string} id Boat Photo ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPhotosIdDelete(boatId: string, id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a boat photo
     * @param {string} boatId Boat ID
     * @param {string} id Boat Photo ID
     * @param {DomesticV2BoatsBoatIdPhotosIdDeleteRequest} [boatPhoto] To update boat photo sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdPhotosIdPatch(boatId: string, id: string, boatPhoto?: DomesticV2BoatsBoatIdPhotosIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Fetch listing reviews 
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdReviewsGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<Array<BoatReviews>>;

    /**
     * Get a list of similar boats up to 4 boats.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdSimilarBoatsGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * Get the boat\'s smart pricing
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdSmartPricingGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsBoatIdSmartPricingGet200Response>;

    /**
     * Submit for approval
     * @param {string} boatId 
     * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdSubmitForApprovalPost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig): AxiosPromise<Boat>;

    /**
     * Get temporary package details of the boat. It\'s like consolidated package for one input pricing and sync to current two packages.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdTemporaryPackageGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<TemporaryPackage>;

    /**
     * Update or create temporary package of the boat. It\'s like consolidated package for one input pricing and sync with current two packages.
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdTemporaryPackageGetRequest} [temporaryPackage] Temporary package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsBoatIdTemporaryPackagePut(boatId: string, temporaryPackage?: DomesticV2BoatsBoatIdTemporaryPackageGetRequest, options?: AxiosRequestConfig): AxiosPromise<TemporaryPackage>;

    /**
     * Gets the earnings estimate for the boat
     * @param {string} make The boat maker
     * @param {string} boatModel The boat model
     * @param {string} yearManufactured The year the boat was manufactured
     * @param {string} latitude The latitude of the boat
     * @param {string} longitude The longitude of the boat
     * @param {string} countryCode The code of the country of the boat location. ie \&quot;US\&quot;
     * @param {string} state The state of the boat location. ie FL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsEstimatedEarningsCalculatorGet(make: string, boatModel: string, yearManufactured: string, latitude: string, longitude: string, countryCode: string, state: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * Get boat features list
     * @param {number} [length] The boat length
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsFeaturesGet(length?: number, options?: AxiosRequestConfig): AxiosPromise<Array<BoatFeatures>>;

    /**
     * Get boat calendar availalibity
     * @param {string} id Boat Unique ID
     * @param {string} startOn Start date ISO8601 formated (YYYY-MM-DD)
     * @param {string} endOn End date ISO8601 formated (YYYY-MM-DD)
     * @param {string} [bookingId] Booking Unique ID
     * @param {string} [packageType] Indicates the package type selection from the booking form. Required for Buoy Boats. (captained/bareboat)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCalendarGet(id: string, startOn: string, endOn: string, bookingId?: string, packageType?: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsIdCalendarGet200Response>;

    /**
     * Update boat calendar date
     * @param {string} id Boat Unique ID
     * @param {DomesticV2BoatsIdCalendarGetRequest} [calendar] To update a boat calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCalendarPatch(id: string, calendar?: DomesticV2BoatsIdCalendarGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get Boatsetter captain network rate
     * @param {string} id Boat public ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCaptainNetworkRateGet(id: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsIdCaptainNetworkRateGet200Response>;

    /**
     * Get boat captains
     * @param {string} id Boat ID
     * @param {'active deactivated'} [status] The Boat Captain status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCaptainsGet(id: string, status?: 'active deactivated', options?: AxiosRequestConfig): AxiosPromise<Array<Captain>>;

    /**
     * Create a captain invitation
     * @param {string} id Boat ID
     * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Create a captain
     * @param {string} id Boat ID
     * @param {DomesticV2BoatsIdCaptainsGetRequest} [captain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCaptainsPost(id: string, captain?: DomesticV2BoatsIdCaptainsGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get eligible captains
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdCaptainsSearchGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<Captain>>;

    /**
     * Boat date price details
     * @param {string} id Boat Unique ID
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdDatePricingDetailsGet(id: string, date: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsIdDatePricingDetailsGet200Response>;

    /**
     * Determine if boat may be deleted
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdDeletableGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Deletes the boat. Only approved boats with no blocking bookings or incomplete boats are deletable
     * @param {string} id Boat Unique ID
     * @param {DomesticV2BoatsIdDeleteRequest} [boat] Delete boat params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdDelete(id: string, boat?: DomesticV2BoatsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get boat detail
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Boat>;

    /**
     * Increments boats page view count
     * @param {string} id The boat public_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdIncrementPageViewPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Boat ID
     * @param {DomesticV2BoatsIdLienholderPutRequest} [lienholderInfo] Creates the boat lienholder information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdLienholderPut(id: string, lienholderInfo?: DomesticV2BoatsIdLienholderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update boat
     * @param {string} id Boat Unique ID
     * @param {BoatInsertOrUpdate} [boat] To update a boat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdPatch(id: string, boat?: BoatInsertOrUpdate, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get boat photos list
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdPhotosGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<BoatPhoto>>;

    /**
     * Save boat photos
     * @param {string} id Boat ID
     * @param {any} photos Boat photos file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdPhotosPost(id: string, photos: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * User dashboard stats
     * @param {string} id Boat Unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsIdStatsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BoatsIdStatsGet200Response>;

    /**
     * Get boat makes list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsMakesGet(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * Get boat models list
     * @param {string} make The boat make
     * @param {number} year The boat year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsModelsGet(make: string, year: number, options?: AxiosRequestConfig): AxiosPromise<Array<BoatModel>>;

    /**
     * Creates a new boat
     * @param {BoatInsertOrUpdate} body New Boat Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsPost(body: BoatInsertOrUpdate, options?: AxiosRequestConfig): AxiosPromise<Boat>;

    /**
     * Gets the expected pricing
     * @param {string} packageId The package ID
     * @param {number} price The package price in cents
     * @param {'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration
     * @param {boolean} [includeCaptainPrice] This option wil override the package option. include_captain_price: false (I will provide the list), include_captain_price: true (Boatsetter will provide the list)
     * @param {number} [customCaptainPrice] 
     * @param {string} [captainPaymentCollection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsPriceCalculatorGet(packageId: string, price: number, duration: 'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours', includeCaptainPrice?: boolean, customCaptainPrice?: number, captainPaymentCollection?: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * Search boats
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {'captained' | 'bareboat'} [packageType] The package type
     * @param {string} [make] The boat make
     * @param {Array<string>} [makes] The boat makes
     * @param {number} [passengers] The number of passengers
     * @param {number} [passengerMin] The min number of passengers
     * @param {number} [passengerMax] The max number of passengers
     * @param {number} [ratingMin] The min rating
     * @param {number} [ratingMax] The max rating
     * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>} [activities] Boat activities
     * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
     * @param {string} [category] The boat category
     * @param {'sail' | 'power'} [boatType] The type of boat
     * @param {Array<string>} [features] The boat features
     * @param {number} [lengthMin] The min boat length in feet
     * @param {number} [lengthMax] The max boat length in feet
     * @param {number} [priceMin] The min booking price in cents
     * @param {number} [priceMax] The max booking price in cents
     * @param {boolean} [instantBookable] Include instant bookable boats only. Value can be anything, e.g: true
     * @param {number} [lat] Latitude of the center search coordinate. If used, lng is required.
     * @param {number} [lng] Longitude of the center search coordinate. If used, lat is required
     * @param {string} [latLngNe] Comma separate lat,lng. E.g. 38.23983070389973,-122.16784287659897
     * @param {string} [latLngSw] Comma separated lat,lng. E.g., 37.046990106661035,-122.60454941956772
     * @param {number} [distance] The distance in meters from the search center
     * @param {'best_match' | 'price_high_to_low' | 'price_low_to_high'} [sortBy] The sort method.
     * @param {Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>} [durations] The durations available of boat
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV2BoatsSearchGet(perPage?: number, page?: number, packageType?: 'captained' | 'bareboat', make?: string, makes?: Array<string>, passengers?: number, passengerMin?: number, passengerMax?: number, ratingMin?: number, ratingMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>, tripDate?: string, category?: string, boatType?: 'sail' | 'power', features?: Array<string>, lengthMin?: number, lengthMax?: number, priceMin?: number, priceMax?: number, instantBookable?: boolean, lat?: number, lng?: number, latLngNe?: string, latLngSw?: string, distance?: number, sortBy?: 'best_match' | 'price_high_to_low' | 'price_low_to_high', durations?: Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>, options?: AxiosRequestConfig): AxiosPromise<Array<Boat>>;

    /**
     * Returns a list of selectable packages
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV3BoatsBoatIdAvailablePackagesGet(boatId: string, options?: AxiosRequestConfig): AxiosPromise<BoatAvailablePackages>;

    /**
     * Submits a boat to the insurance provider
     * @param {string} boatId 
     * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    domesticV3BoatsBoatIdSubmitToInsurancePost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig): AxiosPromise<InsuranceOwnerPolicy>;

}

/**
 * BoatsApi - object-oriented interface
 * @export
 * @class BoatsApi
 * @extends {BaseAPI}
 */
export class BoatsApi extends BaseAPI implements BoatsApiInterface {
    /**
     * Create a captain invitation
     * @param {string} id Boat ID
     * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV1BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV1BoatsIdCaptainsInvitePost(id, invite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get any requirements due to activate the boat
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdActivationRequirementsGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdActivationRequirementsGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all add ons of the boat.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdAddOnsGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdAddOnsGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete boat add on.
     * @param {string} boatId Boat ID
     * @param {number} id ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdAddOnsIdDelete(boatId: string, id: number, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdAddOnsIdDelete(boatId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update existing boat add on.
     * @param {string} boatId Boat ID
     * @param {number} id ID
     * @param {DomesticV2BoatsBoatIdAddOnsIdDeleteRequest} [boatAddOn] Boat add on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdAddOnsIdPatch(boatId: string, id: number, boatAddOn?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdAddOnsIdPatch(boatId, id, boatAddOn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new boat add on.
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdAddOnsGetRequest} [boatAddOn] Boat add on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdAddOnsPost(boatId: string, boatAddOn?: DomesticV2BoatsBoatIdAddOnsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdAddOnsPost(boatId, boatAddOn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * COI file
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdCoiDeleteDelete(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdCoiDeleteDelete(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} boatId The boat public_id
     * @param {DomesticV2BoatsBoatIdCoiPutRequest} [cOIFile] COI file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdCoiPut(boatId: string, cOIFile?: DomesticV2BoatsBoatIdCoiPutRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdCoiPut(boatId, cOIFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * COI file
     * @param {string} boatId 
     * @param {any} [coiFile] COI file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdCoiUploadPost(boatId: string, coiFile?: any, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdCoiUploadPost(boatId, coiFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update instant book settings
     * @param {string} boatId 
     * @param {DomesticV2BoatsBoatIdInstantBookPutRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdInstantBookPut(boatId: string, body: DomesticV2BoatsBoatIdInstantBookPutRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdInstantBookPut(boatId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the boat\'s insurance status
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdInsuranceGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdInsuranceGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit insurance questions
     * @param {string} boatId 
     * @param {DomesticV2BoatsBoatIdInsuranceGetRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdInsurancePost(boatId: string, body: DomesticV2BoatsBoatIdInsuranceGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdInsurancePost(boatId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the boat\'s insurance statuses
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdInsurancesGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdInsurancesGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List a available boat packages
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPackagesGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPackagesGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete one or more boat packages
     * @param {string} boatId Boat ID
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPackagesIdDelete(boatId: string, id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPackagesIdDelete(boatId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a existing boat package
     * @param {string} boatId Boat ID
     * @param {string} id Package ID
     * @param {DomesticV2BoatsBoatIdPackagesIdDeleteRequest} [_package] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPackagesIdPatch(boatId: string, id: string, _package?: DomesticV2BoatsBoatIdPackagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPackagesIdPatch(boatId, id, _package, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save a new boat packages
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdPackagesGetRequest} [_package] To save a boat package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPackagesPost(boatId: string, _package?: DomesticV2BoatsBoatIdPackagesGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPackagesPost(boatId, _package, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a boat photos
     * @param {string} boatId Boat ID
     * @param {string} id Boat Photo ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPhotosIdDelete(boatId: string, id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPhotosIdDelete(boatId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a boat photo
     * @param {string} boatId Boat ID
     * @param {string} id Boat Photo ID
     * @param {DomesticV2BoatsBoatIdPhotosIdDeleteRequest} [boatPhoto] To update boat photo sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdPhotosIdPatch(boatId: string, id: string, boatPhoto?: DomesticV2BoatsBoatIdPhotosIdDeleteRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdPhotosIdPatch(boatId, id, boatPhoto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch listing reviews 
     * @param {string} boatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdReviewsGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdReviewsGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of similar boats up to 4 boats.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdSimilarBoatsGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdSimilarBoatsGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the boat\'s smart pricing
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdSmartPricingGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdSmartPricingGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit for approval
     * @param {string} boatId 
     * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdSubmitForApprovalPost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdSubmitForApprovalPost(boatId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get temporary package details of the boat. It\'s like consolidated package for one input pricing and sync to current two packages.
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdTemporaryPackageGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdTemporaryPackageGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update or create temporary package of the boat. It\'s like consolidated package for one input pricing and sync with current two packages.
     * @param {string} boatId Boat ID
     * @param {DomesticV2BoatsBoatIdTemporaryPackageGetRequest} [temporaryPackage] Temporary package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsBoatIdTemporaryPackagePut(boatId: string, temporaryPackage?: DomesticV2BoatsBoatIdTemporaryPackageGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsBoatIdTemporaryPackagePut(boatId, temporaryPackage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the earnings estimate for the boat
     * @param {string} make The boat maker
     * @param {string} boatModel The boat model
     * @param {string} yearManufactured The year the boat was manufactured
     * @param {string} latitude The latitude of the boat
     * @param {string} longitude The longitude of the boat
     * @param {string} countryCode The code of the country of the boat location. ie \&quot;US\&quot;
     * @param {string} state The state of the boat location. ie FL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsEstimatedEarningsCalculatorGet(make: string, boatModel: string, yearManufactured: string, latitude: string, longitude: string, countryCode: string, state: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsEstimatedEarningsCalculatorGet(make, boatModel, yearManufactured, latitude, longitude, countryCode, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat features list
     * @param {number} [length] The boat length
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsFeaturesGet(length?: number, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsFeaturesGet(length, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat calendar availalibity
     * @param {string} id Boat Unique ID
     * @param {string} startOn Start date ISO8601 formated (YYYY-MM-DD)
     * @param {string} endOn End date ISO8601 formated (YYYY-MM-DD)
     * @param {string} [bookingId] Booking Unique ID
     * @param {string} [packageType] Indicates the package type selection from the booking form. Required for Buoy Boats. (captained/bareboat)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCalendarGet(id: string, startOn: string, endOn: string, bookingId?: string, packageType?: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCalendarGet(id, startOn, endOn, bookingId, packageType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update boat calendar date
     * @param {string} id Boat Unique ID
     * @param {DomesticV2BoatsIdCalendarGetRequest} [calendar] To update a boat calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCalendarPatch(id: string, calendar?: DomesticV2BoatsIdCalendarGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCalendarPatch(id, calendar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Boatsetter captain network rate
     * @param {string} id Boat public ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCaptainNetworkRateGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCaptainNetworkRateGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat captains
     * @param {string} id Boat ID
     * @param {'active deactivated'} [status] The Boat Captain status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCaptainsGet(id: string, status?: 'active deactivated', options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCaptainsGet(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a captain invitation
     * @param {string} id Boat ID
     * @param {DomesticV1BoatsIdCaptainsInvitePostRequest} [invite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCaptainsInvitePost(id: string, invite?: DomesticV1BoatsIdCaptainsInvitePostRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCaptainsInvitePost(id, invite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a captain
     * @param {string} id Boat ID
     * @param {DomesticV2BoatsIdCaptainsGetRequest} [captain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCaptainsPost(id: string, captain?: DomesticV2BoatsIdCaptainsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCaptainsPost(id, captain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get eligible captains
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdCaptainsSearchGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdCaptainsSearchGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Boat date price details
     * @param {string} id Boat Unique ID
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdDatePricingDetailsGet(id: string, date: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdDatePricingDetailsGet(id, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine if boat may be deleted
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdDeletableGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdDeletableGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the boat. Only approved boats with no blocking bookings or incomplete boats are deletable
     * @param {string} id Boat Unique ID
     * @param {DomesticV2BoatsIdDeleteRequest} [boat] Delete boat params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdDelete(id: string, boat?: DomesticV2BoatsIdDeleteRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdDelete(id, boat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat detail
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Increments boats page view count
     * @param {string} id The boat public_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdIncrementPageViewPost(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdIncrementPageViewPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Boat ID
     * @param {DomesticV2BoatsIdLienholderPutRequest} [lienholderInfo] Creates the boat lienholder information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdLienholderPut(id: string, lienholderInfo?: DomesticV2BoatsIdLienholderPutRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdLienholderPut(id, lienholderInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update boat
     * @param {string} id Boat Unique ID
     * @param {BoatInsertOrUpdate} [boat] To update a boat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdPatch(id: string, boat?: BoatInsertOrUpdate, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdPatch(id, boat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat photos list
     * @param {string} id Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdPhotosGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdPhotosGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save boat photos
     * @param {string} id Boat ID
     * @param {any} photos Boat photos file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdPhotosPost(id: string, photos: any, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdPhotosPost(id, photos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User dashboard stats
     * @param {string} id Boat Unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsIdStatsGet(id: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsIdStatsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat makes list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsMakesGet(options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsMakesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get boat models list
     * @param {string} make The boat make
     * @param {number} year The boat year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsModelsGet(make: string, year: number, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsModelsGet(make, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new boat
     * @param {BoatInsertOrUpdate} body New Boat Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsPost(body: BoatInsertOrUpdate, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the expected pricing
     * @param {string} packageId The package ID
     * @param {number} price The package price in cents
     * @param {'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours'} duration The trip duration
     * @param {boolean} [includeCaptainPrice] This option wil override the package option. include_captain_price: false (I will provide the list), include_captain_price: true (Boatsetter will provide the list)
     * @param {number} [customCaptainPrice] 
     * @param {string} [captainPaymentCollection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsPriceCalculatorGet(packageId: string, price: number, duration: 'half_day' | 'full_day' | 'two_hours' | 'three_hours' | 'six_hours', includeCaptainPrice?: boolean, customCaptainPrice?: number, captainPaymentCollection?: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsPriceCalculatorGet(packageId, price, duration, includeCaptainPrice, customCaptainPrice, captainPaymentCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search boats
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {'captained' | 'bareboat'} [packageType] The package type
     * @param {string} [make] The boat make
     * @param {Array<string>} [makes] The boat makes
     * @param {number} [passengers] The number of passengers
     * @param {number} [passengerMin] The min number of passengers
     * @param {number} [passengerMax] The max number of passengers
     * @param {number} [ratingMin] The min rating
     * @param {number} [ratingMax] The max rating
     * @param {Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>} [activities] Boat activities
     * @param {string} [tripDate] The trip start date, formatted YYYY-MM-DD
     * @param {string} [category] The boat category
     * @param {'sail' | 'power'} [boatType] The type of boat
     * @param {Array<string>} [features] The boat features
     * @param {number} [lengthMin] The min boat length in feet
     * @param {number} [lengthMax] The max boat length in feet
     * @param {number} [priceMin] The min booking price in cents
     * @param {number} [priceMax] The max booking price in cents
     * @param {boolean} [instantBookable] Include instant bookable boats only. Value can be anything, e.g: true
     * @param {number} [lat] Latitude of the center search coordinate. If used, lng is required.
     * @param {number} [lng] Longitude of the center search coordinate. If used, lat is required
     * @param {string} [latLngNe] Comma separate lat,lng. E.g. 38.23983070389973,-122.16784287659897
     * @param {string} [latLngSw] Comma separated lat,lng. E.g., 37.046990106661035,-122.60454941956772
     * @param {number} [distance] The distance in meters from the search center
     * @param {'best_match' | 'price_high_to_low' | 'price_low_to_high'} [sortBy] The sort method.
     * @param {Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>} [durations] The durations available of boat
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV2BoatsSearchGet(perPage?: number, page?: number, packageType?: 'captained' | 'bareboat', make?: string, makes?: Array<string>, passengers?: number, passengerMin?: number, passengerMax?: number, ratingMin?: number, ratingMax?: number, activities?: Array<'celebrating' | 'fishing' | 'sailing' | 'watersports' | 'cruising'>, tripDate?: string, category?: string, boatType?: 'sail' | 'power', features?: Array<string>, lengthMin?: number, lengthMax?: number, priceMin?: number, priceMax?: number, instantBookable?: boolean, lat?: number, lng?: number, latLngNe?: string, latLngSw?: string, distance?: number, sortBy?: 'best_match' | 'price_high_to_low' | 'price_low_to_high', durations?: Array<'2hrs' | '4hrs' | '8hrs' | 'multy_day'>, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV2BoatsSearchGet(perPage, page, packageType, make, makes, passengers, passengerMin, passengerMax, ratingMin, ratingMax, activities, tripDate, category, boatType, features, lengthMin, lengthMax, priceMin, priceMax, instantBookable, lat, lng, latLngNe, latLngSw, distance, sortBy, durations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of selectable packages
     * @param {string} boatId Boat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV3BoatsBoatIdAvailablePackagesGet(boatId: string, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV3BoatsBoatIdAvailablePackagesGet(boatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits a boat to the insurance provider
     * @param {string} boatId 
     * @param {DomesticV3BoatsBoatIdSubmitToInsurancePostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public domesticV3BoatsBoatIdSubmitToInsurancePost(boatId: string, body?: DomesticV3BoatsBoatIdSubmitToInsurancePostRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).domesticV3BoatsBoatIdSubmitToInsurancePost(boatId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
