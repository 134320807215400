import Bugsnag from '@bugsnag/js';
import React from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import getConfig from 'next/config';
import { IS_CLIENT } from './app/utils';

const { BUGSNAG_KEY, BS_ENV, APP_VERSION } = getConfig().publicRuntimeConfig;

const init = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    appVersion: APP_VERSION || '0.2.1',
    appType: IS_CLIENT ? 'client' : 'server',
    releaseStage: BS_ENV || 'development',
    enabledReleaseStages: ['staging', 'production'],
    plugins: [new BugsnagPluginReact(React)],
  });
  BugsnagPerformance.start({
    apiKey: BUGSNAG_KEY,
    appVersion: APP_VERSION || '0.2.1',
    releaseStage: BS_ENV || 'development',
    enabledReleaseStages: ['staging', 'production'],
  });
};

export default init;

export const telemetry = (label: string, payload = {}) => {
  if (IS_CLIENT) {
    Bugsnag.leaveBreadcrumb(label, payload);
  }
};

export const logError = (error) => {
  console.warn(error);
  Bugsnag.notify(error);
};
