import Head from 'next/head';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();
const { RAILS_URL } = getConfig().publicRuntimeConfig;

const defaultValues = {
  title: 'Boat Rentals, Charter Boat Rentals, House Boat Rentals on Boatsetter',
  description:
    'Rent a boat today, with or without a boat captain, and get out on the water! Browse our huge selection of boat rentals near you including yachts, pontoons, sailboats, fishing boats, wakeboarding boats, Catamarans & more!',
  ogDescription:
    'Rent a boat today, with or without a boat captain, and get out on the water! Browse our huge selection of boat rentals near you including yachts, pontoons, sailboats, fishing boats, wakeboarding boats, Catamarans & more!',
  ogImage: '',
  ogSiteName: 'Boatsetter',
  email: '',
};

interface Props {
  ogDescription?: string;
  description?: string;
  title?: string;
  titleSuffix?: boolean;
  ogImage?: string;
  ogType?: string;
  noIndex?: boolean;
}

const isSearch = (pathname: string): boolean => pathname === '/a/boat-rentals/search';

function SEO({ ogDescription, description, title, titleSuffix = true, ogImage, ogType = 'website', noIndex }: Props) {
  const locProps = {
    description,
    ogDescription,
    title,
    titleSuffix,
    ogImage,
    noIndex,
    ogType,
  };
  const router = useRouter();

  const finalProps: any = Object.keys(locProps).reduce((acc, propKey) => {
    acc[propKey] = locProps[propKey] || defaultValues[propKey];
    return acc;
  }, {});

  let desc = finalProps.description.replace(/(\r\n|\n|\r)/gm, '').replace(/(<([^>]+)>)/gi, '');
  let ogDesc = finalProps.ogDescription.replace(/(\r\n|\n|\r)/gm, '').replace(/(<([^>]+)>)/gi, '');

  const maxDescLength = 170;
  desc = desc.length > maxDescLength ? `${desc.substring(0, maxDescLength - 3)}...` : desc;
  ogDesc = ogDesc.length > maxDescLength ? `${ogDesc.substring(0, maxDescLength - 3)}...` : ogDesc;

  const pageWithoutParams = router.asPath.split('?')[0];
  const ogUrlWithoutParams = `${RAILS_URL}${pageWithoutParams}`;

  const isSearchPage = isSearch(router.pathname);

  return (
    <Head>
      <title>{finalProps.title}</title>
      {finalProps.noIndex === true && <meta name="robots" content="noindex, nofollow" />}
      <meta content="Boatsetter" name="apple-mobile-web-app-title" />
      <meta content="black-translucent" name="apple-mobile-web-app-status-bar-style" />

      <meta content="app-id=1449873080" name="apple-app" />
      <meta content="app-id=com.boatsetterrenters" name="google-app" />

      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
      <meta name="HandheldFriendly" content="true" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="author" content="Boatsetter" />
      <meta name="description" content={desc} />
      <meta property="og:image" content={finalProps.ogImage} />
      <meta property="og:description" content={ogDesc} />
      <meta property="og:title" content={finalProps.title} />
      <meta property="og:type" content={finalProps.ogType} />
      <meta property="og:site_name" content="Boatsetter" />
      {isSearchPage && <meta property="og:url" content={`${RAILS_URL}${router.asPath}`} />}
      {!isSearchPage && <meta property="og:url" content={ogUrlWithoutParams} />}
      <meta property="og:email" content={finalProps.email} />
      <meta property="fb:app_id" content={publicRuntimeConfig.FACEBOOK_APP_ID} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalProps.title} />
      <meta name="twitter:site" content="@boatsetter" />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:creator" content="Boatsetter" />
      <meta name="twitter:image" content={finalProps.ogImage} />

      <link rel="apple-touch-icon" href="//d1elspe5sgezyc.cloudfront.net/touch-icon-iphone-retina.png" />
      <link rel="apple-touch-icon" href="//d1elspe5sgezyc.cloudfront.net/touch-icon-ipad.png" sizes="76x76" />
      <link
        rel="apple-touch-icon"
        href="//d1elspe5sgezyc.cloudfront.net/touch-icon-iphone-retina.png"
        sizes="120x120"
      />
      <link rel="apple-touch-icon" href="//d1elspe5sgezyc.cloudfront.net/touch-icon-ipad-retina.png" sizes="152x152" />
    </Head>
  );
}

export default SEO;
