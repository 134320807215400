import { QueryKey, useQuery } from 'react-query';
import { QueryFunction } from 'react-query/types/core/types';
import { UseQueryOptions } from 'react-query/types/react/types';
import { useEffect, useState } from 'react';
import { useUserDetails } from 'auth/mutations/userHooks';

// eslint-disable-next-line import/prefer-default-export
export const useAuthQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  const { isAuthenticated } = useUserDetails();
  const isQueryEnabled = isAuthenticated && (options?.enabled ?? true);

  return useQuery(queryKey, queryFn, {
    ...options,
    enabled: isQueryEnabled,
  });
};

export const useIsLoadingMultipleQueries = (
  queries: { isLoading: boolean; isIdle: boolean }[],
  includeStale = false
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(queries.some((q) => (!!q.isLoading || includeStale ? !!q.isIdle : false)));
  }, [queries.map((q) => q.isLoading)]);

  return isLoading;
};
