import {
  ClientActionEventTypeNames,
  Connector,
  getDateTime,
  getLocalTimeZone,
  HttpMethod,
  HttpProtocol,
} from '@boatsetter/analytics-connector/';
import getConfig from 'next/config';
import { useCallback } from 'react';

export const userClickAnalyticsTrackers = ['analytics'];
const { APP_VERSION, API_EVENT_INTAKE } = getConfig().publicRuntimeConfig;

interface AnalyticsDefaultProps {
  sessionId: string;
  anonymousId: string;
  appScreen?: string;
}

export const getTimeMetadata = (time: Date = new Date()) => ({
  localTs: getDateTime(time),
  localTimezone: getLocalTimeZone(time),
});

type AnalyticsExtraProps = Record<string, string>;

export const useTrackEvent = (): ((
  typename: ClientActionEventTypeNames,
  defaultMetaData: AnalyticsDefaultProps,
  extraMetaData?: AnalyticsExtraProps
) => void) =>
  useCallback(
    (
      typename: ClientActionEventTypeNames,
      defaultMetaData: AnalyticsDefaultProps,
      extraMetaData: AnalyticsExtraProps = {}
    ) => {
      const defaultPayload = {
        ...getTimeMetadata(),
        ...defaultMetaData,
      };

      switch (typename) {
        case 'UserSignedUpWithEmail':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedUpWithFacebook':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedUpWithApple':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedUpWithGoogle':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;

        case 'UserSignedInWithEmail':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedInWithFacebook':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedInWithApple':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserSignedInWithGoogle':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;

        case 'UserViewedPage':
          analyticsConnector.track[typename]({ ...defaultPayload, __typename: typename }, userClickAnalyticsTrackers);
          break;
        case 'UserRequestedBooking':
          analyticsConnector.track[typename](
            { ...defaultPayload, __typename: typename, boatId: extraMetaData.boatId },
            userClickAnalyticsTrackers
          );
          break;
        case 'UserDidInstantBooking':
          analyticsConnector.track[typename](
            { ...defaultPayload, __typename: typename, boatId: extraMetaData.boatId },
            userClickAnalyticsTrackers
          );
          break;
        case 'UserFavouredBoat':
          analyticsConnector.track[typename](
            { ...defaultPayload, __typename: typename, boatId: extraMetaData.boatId },
            userClickAnalyticsTrackers
          );
          break;

        default:
          throw new Error(
            `${typename} event is not found in the @boatsetter/analytics-connector/ClientActionEventTypeNames`
          );
      }
    },
    []
  );

export function trackWebSessionInitialized(userId: string, metaData: AnalyticsDefaultProps) {
  analyticsConnector.track.WebSessionInitialized(
    {
      ...getTimeMetadata(),
      ...metaData,
      __typename: 'WebSessionInitialized',
      publicId: userId,
      referringUrl: document.referrer,
      url: window.location.href,
      userAgent: '', // TODO: add the user agent support
      ipAddress: '', // TODO: add the public ip server side extraction and transformation
      platform: 'WEB',
      appVersion: APP_VERSION || '0.2.1',
    },
    userClickAnalyticsTrackers
  );
}

export const analyticsConnector = new Connector({
  appId: 'marketplace-spa',
  collectorUrl: API_EVENT_INTAKE,
  protocol: HttpProtocol.HTTPS,
  triggerBufferSize: 40,
  method: HttpMethod.POST,
  trackerIds: new Set(['analytics']),
});

export const analyticsConnectorSyncTimeout = setInterval(() => {
  analyticsConnector.forceFlush();
}, 30000);

export default useTrackEvent;
