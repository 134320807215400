import boatsetterPaths from 'utils/boatsetterPaths';

export type AfterAuthType = {
  action: (() => Promise<{ data: any } | void>) | (() => void);
  type: AfterAuthActionType;
  payload?: any;
};

export enum AfterAuthActionType {
  'ADD_FAVORITE_BOAT',
  'CREATE_BOAT',
}

export const SECURED_ROUTES = [
  '/a/bookings/book_now',
  '/a/boat_manager_settings',
  boatsetterPaths.listBoat,
  'open_message_modal',
];

export enum LoginType {
  'EMAIL_LOGIN' = 'EMAIL_LOGIN',
  'GOOGLE_LOGIN' = 'GOOGLE_LOGIN',
  'APPLE_LOGIN' = 'APPLE_LOGIN',
  'FACEBOOK_LOGIN' = 'FACEBOOK_LOGIN',
  'NONE' = 'NONE',
}
