/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppleAuthAuthorizationResponse } from '../models';
// @ts-ignore
import { AppleAuthCallback } from '../models';
// @ts-ignore
import { AppleAuthCallbackPost401Response } from '../models';
// @ts-ignore
import { AppleAuthCallbackPost422Response } from '../models';
// @ts-ignore
import { AppleAuthConnect } from '../models';
// @ts-ignore
import { Boat } from '../models';
// @ts-ignore
import { Booking } from '../models';
// @ts-ignore
import { DomesticV2MeAddressPatchRequest } from '../models';
// @ts-ignore
import { DomesticV2MeAvatarPutRequest } from '../models';
// @ts-ignore
import { DomesticV2MeGetRequest } from '../models';
// @ts-ignore
import { DomesticV2MeInboxCountGet200Response } from '../models';
// @ts-ignore
import { DomesticV2MeInboxGet200ResponseInner } from '../models';
// @ts-ignore
import { DomesticV2MeNotificationPreferencesGetRequest } from '../models';
// @ts-ignore
import { DomesticV2MePasswordForgotPostRequest } from '../models';
// @ts-ignore
import { DomesticV2MePasswordPatchRequest } from '../models';
// @ts-ignore
import { DomesticV2MePhonesVerificationCheckPostRequest } from '../models';
// @ts-ignore
import { DomesticV2MePhonesVerificationStartPostRequest } from '../models';
// @ts-ignore
import { DomesticV2MeStatsGet200Response } from '../models';
// @ts-ignore
import { DomesticV2MeTripsIdSharePostRequest } from '../models';
// @ts-ignore
import { DomesticV2UsersPublicIdGetRequest } from '../models';
// @ts-ignore
import { DomesticV3MeBoatsGet200ResponseInner } from '../models';
// @ts-ignore
import { Jwt } from '../models';
// @ts-ignore
import { LatestBookingMessage } from '../models';
// @ts-ignore
import { NotificationPreferences } from '../models';
// @ts-ignore
import { OauthPasswordGrant } from '../models';
// @ts-ignore
import { PublicUser } from '../models';
// @ts-ignore
import { Review } from '../models';
// @ts-ignore
import { TimeSeries } from '../models';
// @ts-ignore
import { TokenExchangeFacebookPost200Response } from '../models';
// @ts-ignore
import { TokenExchangeFacebookPostRequest } from '../models';
// @ts-ignore
import { User } from '../models';
// @ts-ignore
import { UserNotification } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authenticate users with sign in with Apple JS. Verify the user\'s identity token with apple servers to confirm that the token is not expired and ensure it has not been tampered with or replayed to the app. Log in the user, register the user or connect the user\'s apple account to the user\'s existing account. You need to call the /apple_auth/connect endpoint first.
         * @param {AppleAuthAuthorizationResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleAuthCallbackPost: async (body?: AppleAuthAuthorizationResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apple_auth/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate a random string for both the Apple nonce and state. These values are essential when implementing Sign in with Apple JS. Please include them in your implementation to ensure a secure and successful authentication process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleAuthConnectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apple_auth/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAddressPatch: async (userAddress?: DomesticV2MeAddressPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAddressPost: async (userAddress?: DomesticV2MeAddressPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2MeAvatarPutRequest} [userAvatar] To update a user profile avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAvatarPut: async (userAvatar?: DomesticV2MeAvatarPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAvatar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List current user boats
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeBoatsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/boats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeBookingsPendingReviewGet: async (perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/bookings_pending_review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Message counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInboxCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/inbox_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the inbox messages
         * @param {'inbox' | 'archived' | 'unread'} [category] Category
         * @param {'renter' | 'owner' | 'captain'} [userType] User Type
         * @param {Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>} [messageTypes] 
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInboxGet: async (category?: 'inbox' | 'archived' | 'unread', userType?: 'renter' | 'owner' | 'captain', messageTypes?: Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (userType !== undefined) {
                localVarQueryParameter['user_type'] = userType;
            }

            if (messageTypes) {
                localVarQueryParameter['message_types'] = messageTypes.join(COLLECTION_FORMATS.csv);
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'archived' | 'unread'} [category] Message category
         * @param {string} [renterName] Renter\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeMessagesGet: async (perPage?: number, page?: number, category?: 'archived' | 'unread', renterName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (renterName !== undefined) {
                localVarQueryParameter['renter_name'] = renterName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeNotificationPreferencesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/notification_preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2MeNotificationPreferencesGetRequest} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeNotificationPreferencesPatch: async (preferences?: DomesticV2MeNotificationPreferencesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/notification_preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a password recovery token if email exists.
         * @param {DomesticV2MePasswordForgotPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePasswordForgotPost: async (payload?: DomesticV2MePasswordForgotPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change account password
         * @param {DomesticV2MePasswordPatchRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePasswordPatch: async (payload?: DomesticV2MePasswordPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DomesticV2MeGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePatch: async (user?: DomesticV2MeGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Cell Number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonePatch: async (payload?: DomesticV2MePhonesVerificationStartPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete a phone verification by posting the received code
         * @param {DomesticV2MePhonesVerificationCheckPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonesVerificationCheckPost: async (payload?: DomesticV2MePhonesVerificationCheckPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/phones/verification/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Very a cell number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonesVerificationStartPost: async (payload?: DomesticV2MePhonesVerificationStartPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/phones/verification/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed'} [status] Booking status
         * @param {string} [boatId] Booking list by boat id
         * @param {'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeRentalsGet: async (perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed', boatId?: string, order?: 'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval', sort?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/rentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (boatId !== undefined) {
                localVarQueryParameter['boat_id'] = boatId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User dashboard stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeStatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTimeseriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived'} [status] Booking status
         * @param {Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>} [states] Booking state
         * @param {string} [boatId] Booking list by boat id
         * @param {'checkout' | 'checkin'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTripsGet: async (perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived', states?: Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>, boatId?: string, order?: 'checkout' | 'checkin', sort?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (states) {
                localVarQueryParameter['states'] = states.join(COLLECTION_FORMATS.csv);
            }

            if (boatId !== undefined) {
                localVarQueryParameter['boat_id'] = boatId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The Booking ID
         * @param {DomesticV2MeTripsIdSharePostRequest} [shareBody] Share body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTripsIdSharePost: async (id: string, shareBody?: DomesticV2MeTripsIdSharePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2MeTripsIdSharePost', 'id', id)
            const localVarPath = `/domestic/v2/me/trips/{id}/share`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'renter' | 'owner' | 'captain' | 'partner'} [viewingAs] The viewing user type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeUserNotificationsGet: async (viewingAs?: 'renter' | 'owner' | 'captain' | 'partner', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/user_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (viewingAs !== undefined) {
                localVarQueryParameter['viewing_as'] = viewingAs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdBoatsGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdBoatsGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}/boats`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {DomesticV2UsersPublicIdGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdPatch: async (publicId: string, user?: DomesticV2UsersPublicIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdPatch', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdReviewsGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdReviewsGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}/reviews`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List current user boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3MeBoatsGet: async (perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v3/me/boats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OauthPasswordGrant} [credentials] Client Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenUnamePassPost: async (credentials?: OauthPasswordGrant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/token(uname/pass)`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenExchangeFacebookPost: async (body?: TokenExchangeFacebookPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token_exchange/facebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenExchangeGooglePost: async (body?: TokenExchangeFacebookPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token_exchange/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Authenticate users with sign in with Apple JS. Verify the user\'s identity token with apple servers to confirm that the token is not expired and ensure it has not been tampered with or replayed to the app. Log in the user, register the user or connect the user\'s apple account to the user\'s existing account. You need to call the /apple_auth/connect endpoint first.
         * @param {AppleAuthAuthorizationResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appleAuthCallbackPost(body?: AppleAuthAuthorizationResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleAuthCallback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appleAuthCallbackPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate a random string for both the Apple nonce and state. These values are essential when implementing Sign in with Apple JS. Please include them in your implementation to ensure a secure and successful authentication process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appleAuthConnectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleAuthConnect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appleAuthConnectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeAddressPatch(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeAddressPatch(userAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeAddressPost(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeAddressPost(userAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2MeAvatarPutRequest} [userAvatar] To update a user profile avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeAvatarPut(userAvatar?: DomesticV2MeAvatarPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeAvatarPut(userAvatar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List current user boats
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeBoatsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Boat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeBoatsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeBookingsPendingReviewGet(perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeBookingsPendingReviewGet(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Message counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeInboxCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2MeInboxCountGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeInboxCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the inbox messages
         * @param {'inbox' | 'archived' | 'unread'} [category] Category
         * @param {'renter' | 'owner' | 'captain'} [userType] User Type
         * @param {Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>} [messageTypes] 
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeInboxGet(category?: 'inbox' | 'archived' | 'unread', userType?: 'renter' | 'owner' | 'captain', messageTypes?: Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomesticV2MeInboxGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeInboxGet(category, userType, messageTypes, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'archived' | 'unread'} [category] Message category
         * @param {string} [renterName] Renter\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeMessagesGet(perPage?: number, page?: number, category?: 'archived' | 'unread', renterName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatestBookingMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeMessagesGet(perPage, page, category, renterName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeNotificationPreferencesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeNotificationPreferencesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2MeNotificationPreferencesGetRequest} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeNotificationPreferencesPatch(preferences?: DomesticV2MeNotificationPreferencesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeNotificationPreferencesPatch(preferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send a password recovery token if email exists.
         * @param {DomesticV2MePasswordForgotPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePasswordForgotPost(payload?: DomesticV2MePasswordForgotPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePasswordForgotPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change account password
         * @param {DomesticV2MePasswordPatchRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePasswordPatch(payload?: DomesticV2MePasswordPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePasswordPatch(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DomesticV2MeGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePatch(user?: DomesticV2MeGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePatch(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Cell Number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePhonePatch(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePhonePatch(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Complete a phone verification by posting the received code
         * @param {DomesticV2MePhonesVerificationCheckPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePhonesVerificationCheckPost(payload?: DomesticV2MePhonesVerificationCheckPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePhonesVerificationCheckPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Very a cell number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MePhonesVerificationStartPost(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MePhonesVerificationStartPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed'} [status] Booking status
         * @param {string} [boatId] Booking list by boat id
         * @param {'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeRentalsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed', boatId?: string, order?: 'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval', sort?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeRentalsGet(perPage, page, status, boatId, order, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User dashboard stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeStatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2MeStatsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeStatsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeTimeseriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSeries>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeTimeseriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived'} [status] Booking status
         * @param {Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>} [states] Booking state
         * @param {string} [boatId] Booking list by boat id
         * @param {'checkout' | 'checkin'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeTripsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived', states?: Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>, boatId?: string, order?: 'checkout' | 'checkin', sort?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeTripsGet(perPage, page, status, states, boatId, order, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id The Booking ID
         * @param {DomesticV2MeTripsIdSharePostRequest} [shareBody] Share body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeTripsIdSharePost(id: string, shareBody?: DomesticV2MeTripsIdSharePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeTripsIdSharePost(id, shareBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'renter' | 'owner' | 'captain' | 'partner'} [viewingAs] The viewing user type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeUserNotificationsGet(viewingAs?: 'renter' | 'owner' | 'captain' | 'partner', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeUserNotificationsGet(viewingAs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Boat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdBoatsGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {DomesticV2UsersPublicIdGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdPatch(publicId: string, user?: DomesticV2UsersPublicIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdPatch(publicId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdReviewsGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List current user boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV3MeBoatsGet(perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomesticV3MeBoatsGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV3MeBoatsGet(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OauthPasswordGrant} [credentials] Client Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthTokenUnamePassPost(credentials?: OauthPasswordGrant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthTokenUnamePassPost(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenExchangeFacebookPost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenExchangeFacebookPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenExchangeFacebookPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenExchangeGooglePost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenExchangeFacebookPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenExchangeGooglePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * Authenticate users with sign in with Apple JS. Verify the user\'s identity token with apple servers to confirm that the token is not expired and ensure it has not been tampered with or replayed to the app. Log in the user, register the user or connect the user\'s apple account to the user\'s existing account. You need to call the /apple_auth/connect endpoint first.
         * @param {AppleAuthAuthorizationResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleAuthCallbackPost(body?: AppleAuthAuthorizationResponse, options?: any): AxiosPromise<AppleAuthCallback> {
            return localVarFp.appleAuthCallbackPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate a random string for both the Apple nonce and state. These values are essential when implementing Sign in with Apple JS. Please include them in your implementation to ensure a secure and successful authentication process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleAuthConnectGet(options?: any): AxiosPromise<AppleAuthConnect> {
            return localVarFp.appleAuthConnectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAddressPatch(userAddress?: DomesticV2MeAddressPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MeAddressPatch(userAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAddressPost(userAddress?: DomesticV2MeAddressPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MeAddressPost(userAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2MeAvatarPutRequest} [userAvatar] To update a user profile avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeAvatarPut(userAvatar?: DomesticV2MeAvatarPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MeAvatarPut(userAvatar, options).then((request) => request(axios, basePath));
        },
        /**
         * List current user boats
         * @param {number} [page] page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeBoatsGet(page?: number, options?: any): AxiosPromise<Array<Boat>> {
            return localVarFp.domesticV2MeBoatsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeBookingsPendingReviewGet(perPage?: number, page?: number, options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.domesticV2MeBookingsPendingReviewGet(perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeGet(options?: any): AxiosPromise<User> {
            return localVarFp.domesticV2MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Message counts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInboxCountGet(options?: any): AxiosPromise<DomesticV2MeInboxCountGet200Response> {
            return localVarFp.domesticV2MeInboxCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the inbox messages
         * @param {'inbox' | 'archived' | 'unread'} [category] Category
         * @param {'renter' | 'owner' | 'captain'} [userType] User Type
         * @param {Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>} [messageTypes] 
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeInboxGet(category?: 'inbox' | 'archived' | 'unread', userType?: 'renter' | 'owner' | 'captain', messageTypes?: Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>, perPage?: number, page?: number, options?: any): AxiosPromise<Array<DomesticV2MeInboxGet200ResponseInner>> {
            return localVarFp.domesticV2MeInboxGet(category, userType, messageTypes, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'archived' | 'unread'} [category] Message category
         * @param {string} [renterName] Renter\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeMessagesGet(perPage?: number, page?: number, category?: 'archived' | 'unread', renterName?: string, options?: any): AxiosPromise<Array<LatestBookingMessage>> {
            return localVarFp.domesticV2MeMessagesGet(perPage, page, category, renterName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeNotificationPreferencesGet(options?: any): AxiosPromise<NotificationPreferences> {
            return localVarFp.domesticV2MeNotificationPreferencesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2MeNotificationPreferencesGetRequest} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeNotificationPreferencesPatch(preferences?: DomesticV2MeNotificationPreferencesGetRequest, options?: any): AxiosPromise<NotificationPreferences> {
            return localVarFp.domesticV2MeNotificationPreferencesPatch(preferences, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a password recovery token if email exists.
         * @param {DomesticV2MePasswordForgotPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePasswordForgotPost(payload?: DomesticV2MePasswordForgotPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePasswordForgotPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Change account password
         * @param {DomesticV2MePasswordPatchRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePasswordPatch(payload?: DomesticV2MePasswordPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePasswordPatch(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DomesticV2MeGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePatch(user?: DomesticV2MeGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePatch(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Cell Number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonePatch(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePhonePatch(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete a phone verification by posting the received code
         * @param {DomesticV2MePhonesVerificationCheckPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonesVerificationCheckPost(payload?: DomesticV2MePhonesVerificationCheckPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePhonesVerificationCheckPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Very a cell number
         * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MePhonesVerificationStartPost(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MePhonesVerificationStartPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed'} [status] Booking status
         * @param {string} [boatId] Booking list by boat id
         * @param {'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeRentalsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed', boatId?: string, order?: 'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval', sort?: 'asc' | 'desc', options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.domesticV2MeRentalsGet(perPage, page, status, boatId, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * User dashboard stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeStatsGet(options?: any): AxiosPromise<DomesticV2MeStatsGet200Response> {
            return localVarFp.domesticV2MeStatsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTimeseriesGet(options?: any): AxiosPromise<Array<TimeSeries>> {
            return localVarFp.domesticV2MeTimeseriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [perPage] Number of objects returned at each iteration
         * @param {number} [page] Page number
         * @param {'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived'} [status] Booking status
         * @param {Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>} [states] Booking state
         * @param {string} [boatId] Booking list by boat id
         * @param {'checkout' | 'checkin'} [order] Booking order by booking attribute
         * @param {'asc' | 'desc'} [sort] Booking checkout sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTripsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived', states?: Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>, boatId?: string, order?: 'checkout' | 'checkin', sort?: 'asc' | 'desc', options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.domesticV2MeTripsGet(perPage, page, status, states, boatId, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id The Booking ID
         * @param {DomesticV2MeTripsIdSharePostRequest} [shareBody] Share body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeTripsIdSharePost(id: string, shareBody?: DomesticV2MeTripsIdSharePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MeTripsIdSharePost(id, shareBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'renter' | 'owner' | 'captain' | 'partner'} [viewingAs] The viewing user type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeUserNotificationsGet(viewingAs?: 'renter' | 'owner' | 'captain' | 'partner', options?: any): AxiosPromise<Array<UserNotification>> {
            return localVarFp.domesticV2MeUserNotificationsGet(viewingAs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdBoatsGet(publicId: string, options?: any): AxiosPromise<Boat> {
            return localVarFp.domesticV2UsersPublicIdBoatsGet(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdGet(publicId: string, options?: any): AxiosPromise<PublicUser> {
            return localVarFp.domesticV2UsersPublicIdGet(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {DomesticV2UsersPublicIdGetRequest} [user] To update a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdPatch(publicId: string, user?: DomesticV2UsersPublicIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2UsersPublicIdPatch(publicId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdReviewsGet(publicId: string, options?: any): AxiosPromise<Review> {
            return localVarFp.domesticV2UsersPublicIdReviewsGet(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * List current user boats
         * @param {number} [perPage] The number of boats to return
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3MeBoatsGet(perPage?: number, page?: number, options?: any): AxiosPromise<Array<DomesticV3MeBoatsGet200ResponseInner>> {
            return localVarFp.domesticV3MeBoatsGet(perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OauthPasswordGrant} [credentials] Client Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenUnamePassPost(credentials?: OauthPasswordGrant, options?: any): AxiosPromise<Jwt> {
            return localVarFp.oauthTokenUnamePassPost(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenExchangeFacebookPost(body?: TokenExchangeFacebookPostRequest, options?: any): AxiosPromise<TokenExchangeFacebookPost200Response> {
            return localVarFp.tokenExchangeFacebookPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchange Facebook Access Token for JWT
         * @param {TokenExchangeFacebookPostRequest} [body] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenExchangeGooglePost(body?: TokenExchangeFacebookPostRequest, options?: any): AxiosPromise<TokenExchangeFacebookPost200Response> {
            return localVarFp.tokenExchangeGooglePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - interface
 * @export
 * @interface AccountApi
 */
export interface AccountApiInterface {
    /**
     * Authenticate users with sign in with Apple JS. Verify the user\'s identity token with apple servers to confirm that the token is not expired and ensure it has not been tampered with or replayed to the app. Log in the user, register the user or connect the user\'s apple account to the user\'s existing account. You need to call the /apple_auth/connect endpoint first.
     * @param {AppleAuthAuthorizationResponse} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    appleAuthCallbackPost(body?: AppleAuthAuthorizationResponse, options?: AxiosRequestConfig): AxiosPromise<AppleAuthCallback>;

    /**
     * Generate a random string for both the Apple nonce and state. These values are essential when implementing Sign in with Apple JS. Please include them in your implementation to ensure a secure and successful authentication process.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    appleAuthConnectGet(options?: AxiosRequestConfig): AxiosPromise<AppleAuthConnect>;

    /**
     * 
     * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeAddressPatch(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeAddressPost(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {DomesticV2MeAvatarPutRequest} [userAvatar] To update a user profile avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeAvatarPut(userAvatar?: DomesticV2MeAvatarPutRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * List current user boats
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeBoatsGet(page?: number, options?: AxiosRequestConfig): AxiosPromise<Array<Boat>>;

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeBookingsPendingReviewGet(perPage?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<Array<Booking>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeGet(options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     * Message counts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeInboxCountGet(options?: AxiosRequestConfig): AxiosPromise<DomesticV2MeInboxCountGet200Response>;

    /**
     * Gets the inbox messages
     * @param {'inbox' | 'archived' | 'unread'} [category] Category
     * @param {'renter' | 'owner' | 'captain'} [userType] User Type
     * @param {Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>} [messageTypes] 
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeInboxGet(category?: 'inbox' | 'archived' | 'unread', userType?: 'renter' | 'owner' | 'captain', messageTypes?: Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>, perPage?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DomesticV2MeInboxGet200ResponseInner>>;

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'archived' | 'unread'} [category] Message category
     * @param {string} [renterName] Renter\&#39;s name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeMessagesGet(perPage?: number, page?: number, category?: 'archived' | 'unread', renterName?: string, options?: AxiosRequestConfig): AxiosPromise<Array<LatestBookingMessage>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeNotificationPreferencesGet(options?: AxiosRequestConfig): AxiosPromise<NotificationPreferences>;

    /**
     * 
     * @param {DomesticV2MeNotificationPreferencesGetRequest} [preferences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeNotificationPreferencesPatch(preferences?: DomesticV2MeNotificationPreferencesGetRequest, options?: AxiosRequestConfig): AxiosPromise<NotificationPreferences>;

    /**
     * Send a password recovery token if email exists.
     * @param {DomesticV2MePasswordForgotPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePasswordForgotPost(payload?: DomesticV2MePasswordForgotPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Change account password
     * @param {DomesticV2MePasswordPatchRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePasswordPatch(payload?: DomesticV2MePasswordPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {DomesticV2MeGetRequest} [user] To update a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePatch(user?: DomesticV2MeGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Update a Cell Number
     * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePhonePatch(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Complete a phone verification by posting the received code
     * @param {DomesticV2MePhonesVerificationCheckPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePhonesVerificationCheckPost(payload?: DomesticV2MePhonesVerificationCheckPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Very a cell number
     * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MePhonesVerificationStartPost(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'trip_request' | 'active' | 'upcoming' | 'completed'} [status] Booking status
     * @param {string} [boatId] Booking list by boat id
     * @param {'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval'} [order] Booking order by booking attribute
     * @param {'asc' | 'desc'} [sort] Booking checkout sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeRentalsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed', boatId?: string, order?: 'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval', sort?: 'asc' | 'desc', options?: AxiosRequestConfig): AxiosPromise<Array<Booking>>;

    /**
     * User dashboard stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeStatsGet(options?: AxiosRequestConfig): AxiosPromise<DomesticV2MeStatsGet200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeTimeseriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<TimeSeries>>;

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived'} [status] Booking status
     * @param {Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>} [states] Booking state
     * @param {string} [boatId] Booking list by boat id
     * @param {'checkout' | 'checkin'} [order] Booking order by booking attribute
     * @param {'asc' | 'desc'} [sort] Booking checkout sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeTripsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived', states?: Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>, boatId?: string, order?: 'checkout' | 'checkin', sort?: 'asc' | 'desc', options?: AxiosRequestConfig): AxiosPromise<Array<Booking>>;

    /**
     * 
     * @param {string} id The Booking ID
     * @param {DomesticV2MeTripsIdSharePostRequest} [shareBody] Share body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeTripsIdSharePost(id: string, shareBody?: DomesticV2MeTripsIdSharePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {'renter' | 'owner' | 'captain' | 'partner'} [viewingAs] The viewing user type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2MeUserNotificationsGet(viewingAs?: 'renter' | 'owner' | 'captain' | 'partner', options?: AxiosRequestConfig): AxiosPromise<Array<UserNotification>>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<Boat>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<PublicUser>;

    /**
     * 
     * @param {string} publicId 
     * @param {DomesticV2UsersPublicIdGetRequest} [user] To update a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2UsersPublicIdPatch(publicId: string, user?: DomesticV2UsersPublicIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<Review>;

    /**
     * List current user boats
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    domesticV3MeBoatsGet(perPage?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DomesticV3MeBoatsGet200ResponseInner>>;

    /**
     * 
     * @param {OauthPasswordGrant} [credentials] Client Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    oauthTokenUnamePassPost(credentials?: OauthPasswordGrant, options?: AxiosRequestConfig): AxiosPromise<Jwt>;

    /**
     * Exchange Facebook Access Token for JWT
     * @param {TokenExchangeFacebookPostRequest} [body] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    tokenExchangeFacebookPost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig): AxiosPromise<TokenExchangeFacebookPost200Response>;

    /**
     * Exchange Facebook Access Token for JWT
     * @param {TokenExchangeFacebookPostRequest} [body] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApiInterface
     */
    tokenExchangeGooglePost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig): AxiosPromise<TokenExchangeFacebookPost200Response>;

}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI implements AccountApiInterface {
    /**
     * Authenticate users with sign in with Apple JS. Verify the user\'s identity token with apple servers to confirm that the token is not expired and ensure it has not been tampered with or replayed to the app. Log in the user, register the user or connect the user\'s apple account to the user\'s existing account. You need to call the /apple_auth/connect endpoint first.
     * @param {AppleAuthAuthorizationResponse} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public appleAuthCallbackPost(body?: AppleAuthAuthorizationResponse, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).appleAuthCallbackPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate a random string for both the Apple nonce and state. These values are essential when implementing Sign in with Apple JS. Please include them in your implementation to ensure a secure and successful authentication process.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public appleAuthConnectGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).appleAuthConnectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeAddressPatch(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeAddressPatch(userAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2MeAddressPatchRequest} [userAddress] To update a user address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeAddressPost(userAddress?: DomesticV2MeAddressPatchRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeAddressPost(userAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2MeAvatarPutRequest} [userAvatar] To update a user profile avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeAvatarPut(userAvatar?: DomesticV2MeAvatarPutRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeAvatarPut(userAvatar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List current user boats
     * @param {number} [page] page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeBoatsGet(page?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeBoatsGet(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeBookingsPendingReviewGet(perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeBookingsPendingReviewGet(perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Message counts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeInboxCountGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeInboxCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the inbox messages
     * @param {'inbox' | 'archived' | 'unread'} [category] Category
     * @param {'renter' | 'owner' | 'captain'} [userType] User Type
     * @param {Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>} [messageTypes] 
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeInboxGet(category?: 'inbox' | 'archived' | 'unread', userType?: 'renter' | 'owner' | 'captain', messageTypes?: Array<'booking_new_message' | 'booking_special_offer' | 'booking_pre_approval' | 'booking_owner_modification' | 'booking_renter_modification' | 'booking_accept_modification' | 'booking_decline_modification' | 'booking_checkout' | 'booking_book_now booking_message_owner' | 'booking_rolodex_cancel' | 'booking_owner_approve' | 'booking_owner_decline' | 'booking_renter_edit_inquiry' | 'booking_owner_edit_inquiry' | 'booking_cancel_modification' | 'booking_expire_modification'>, perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeInboxGet(category, userType, messageTypes, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'archived' | 'unread'} [category] Message category
     * @param {string} [renterName] Renter\&#39;s name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeMessagesGet(perPage?: number, page?: number, category?: 'archived' | 'unread', renterName?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeMessagesGet(perPage, page, category, renterName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeNotificationPreferencesGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeNotificationPreferencesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2MeNotificationPreferencesGetRequest} [preferences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeNotificationPreferencesPatch(preferences?: DomesticV2MeNotificationPreferencesGetRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeNotificationPreferencesPatch(preferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a password recovery token if email exists.
     * @param {DomesticV2MePasswordForgotPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePasswordForgotPost(payload?: DomesticV2MePasswordForgotPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePasswordForgotPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change account password
     * @param {DomesticV2MePasswordPatchRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePasswordPatch(payload?: DomesticV2MePasswordPatchRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePasswordPatch(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DomesticV2MeGetRequest} [user] To update a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePatch(user?: DomesticV2MeGetRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePatch(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Cell Number
     * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePhonePatch(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePhonePatch(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete a phone verification by posting the received code
     * @param {DomesticV2MePhonesVerificationCheckPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePhonesVerificationCheckPost(payload?: DomesticV2MePhonesVerificationCheckPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePhonesVerificationCheckPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Very a cell number
     * @param {DomesticV2MePhonesVerificationStartPostRequest} [payload] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MePhonesVerificationStartPost(payload?: DomesticV2MePhonesVerificationStartPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MePhonesVerificationStartPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'trip_request' | 'active' | 'upcoming' | 'completed'} [status] Booking status
     * @param {string} [boatId] Booking list by boat id
     * @param {'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval'} [order] Booking order by booking attribute
     * @param {'asc' | 'desc'} [sort] Booking checkout sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeRentalsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed', boatId?: string, order?: 'trip_start' | 'trip_finish' | 'created_at' | 'ready_for_approval', sort?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeRentalsGet(perPage, page, status, boatId, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User dashboard stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeStatsGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeStatsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeTimeseriesGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeTimeseriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [perPage] Number of objects returned at each iteration
     * @param {number} [page] Page number
     * @param {'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived'} [status] Booking status
     * @param {Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>} [states] Booking state
     * @param {string} [boatId] Booking list by boat id
     * @param {'checkout' | 'checkin'} [order] Booking order by booking attribute
     * @param {'asc' | 'desc'} [sort] Booking checkout sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeTripsGet(perPage?: number, page?: number, status?: 'trip_request' | 'active' | 'upcoming' | 'completed' | 'archived', states?: Array<'initialized' | 'pre_approved' | 'pending_tos' | 'payment_held' | 'request_canceled' | 'unverified' | 'approved' | 'declined' | 'aboard' | 'ashore' | 'concluded' | 'disputed' | 'cancelled' | 'expired'>, boatId?: string, order?: 'checkout' | 'checkin', sort?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeTripsGet(perPage, page, status, states, boatId, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id The Booking ID
     * @param {DomesticV2MeTripsIdSharePostRequest} [shareBody] Share body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeTripsIdSharePost(id: string, shareBody?: DomesticV2MeTripsIdSharePostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeTripsIdSharePost(id, shareBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'renter' | 'owner' | 'captain' | 'partner'} [viewingAs] The viewing user type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2MeUserNotificationsGet(viewingAs?: 'renter' | 'owner' | 'captain' | 'partner', options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2MeUserNotificationsGet(viewingAs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2UsersPublicIdBoatsGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2UsersPublicIdGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {DomesticV2UsersPublicIdGetRequest} [user] To update a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2UsersPublicIdPatch(publicId: string, user?: DomesticV2UsersPublicIdGetRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2UsersPublicIdPatch(publicId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV2UsersPublicIdReviewsGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List current user boats
     * @param {number} [perPage] The number of boats to return
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public domesticV3MeBoatsGet(perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).domesticV3MeBoatsGet(perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OauthPasswordGrant} [credentials] Client Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public oauthTokenUnamePassPost(credentials?: OauthPasswordGrant, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).oauthTokenUnamePassPost(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchange Facebook Access Token for JWT
     * @param {TokenExchangeFacebookPostRequest} [body] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tokenExchangeFacebookPost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tokenExchangeFacebookPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchange Facebook Access Token for JWT
     * @param {TokenExchangeFacebookPostRequest} [body] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public tokenExchangeGooglePost(body?: TokenExchangeFacebookPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).tokenExchangeGooglePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
