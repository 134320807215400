import { useAtom } from 'jotai';
import { OptionsWithExtraProps, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';
import { isSnackbarInitializedAtom } from './store';

export type EnqueueSnackbarLazyType = (
  message: SnackbarMessage,
  options: OptionsWithExtraProps<VariantType>
) => Promise<SnackbarKey>;

const enqueueSnackbarLazy = async (message, options) => {
  const { enqueueSnackbar } = await import('notistack');
  return enqueueSnackbar?.(message, options);
};

const closeSnackbarLazy = (key?: SnackbarKey) => {
  import('notistack').then((notistack) => {
    notistack.closeSnackbar?.(key);
  });
};

export default function useLazySnackbar() {
  const [isSnackbarInitialized, setIsSnackbarInitialized] = useAtom(isSnackbarInitializedAtom);

  const enqueueSnackbar: EnqueueSnackbarLazyType = async (message, options) => {
    setIsSnackbarInitialized(true);

    return new Promise<SnackbarKey>((resolve) => {
      setTimeout(
        () => {
          resolve(enqueueSnackbarLazy(message, options));
        },
        isSnackbarInitialized ? 0 : 700
      );
    });
  };

  const closeSnackbar = (key?: SnackbarKey) => {
    if (isSnackbarInitialized) {
      closeSnackbarLazy(key);
    }
  };

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
}
