import { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { BoatsApi } from 'swagger/apis/boats-api';
import { AccountApi } from 'swagger/apis/account-api';
import OpenApiConfiguration, { API_CLIENT_HEADERS } from 'api/OpenApiConfiguration';
import { useSetAtom } from 'jotai';
import { writeAfterAuthActionAtom } from 'auth/userStore';
import { AfterAuthActionType } from 'auth/constants';
import { SearchApi } from 'swagger/apis/search-api';
import { Boat } from 'swagger/models';
import { DomesticV1BoatsIdCaptainsInvitePostRequest } from 'swagger/models/domestic-v1-boats-id-captains-invite-post-request';
import { useAuthQuery } from 'utils/queryHooks';
import { useUserDetails } from 'auth/mutations/userHooks';
import useLazySnackbar from 'components/banners/useLazySnackbar';
import getConfig from 'next/config';
import { useLocalStorage } from 'beautiful-react-hooks';
import { useTrackEvent } from 'hooks/useAnalyticsTrack';
import Cookies from 'js-cookie';
import boatsetterPaths from 'utils/boatsetterPaths';
import { getDeletedBoatRedirectBody } from './utils';

const { publicRuntimeConfig } = getConfig();

const boatApi = new BoatsApi(OpenApiConfiguration);
const accountApi = new AccountApi(OpenApiConfiguration);
const searchApi = new SearchApi(OpenApiConfiguration);

const favoritesBoatsApiCall = () => axios.get(`/domestic/v2/me/favorites`);

const getIsTextClamped = (elm) => elm.scrollHeight > elm.clientHeight;

export const useIsTextClamped = <E = HTMLDivElement>(enabled, defaultValue = false) => {
  const textRef = useRef<E>(null);
  const [isTextClamped, setIsTextClamped] = useState<boolean>(defaultValue);
  useEffect(() => {
    if (enabled && textRef.current) {
      setIsTextClamped(getIsTextClamped(textRef.current));
    }
  }, [textRef, enabled]);

  return [isTextClamped, textRef];
};

export const useBottomBarVisible = <E>(smallScreen) => {
  const [bookingWidgetEntry, bookingWidgetRef] = useIntersectionObserver<E>({
    rootMargin: '-60px 100px 0px 0px',
    threshold: 0.3,
  });
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (!scrollContainerRef.current) {
      scrollContainerRef.current = document.querySelector('#__next');
    }
  }, [scrollContainerRef]);

  const bottomBarVisible =
    (!bookingWidgetEntry?.isIntersecting && (scrollContainerRef.current?.scrollTop ?? 0) > 400) || smallScreen;

  return { bookingWidgetRef, bottomBarVisible };
};

// QUERIES
export const useTrips = (boatId, states, isEnabled = true) => {
  const { data: { data: myTrips = [] } = {}, ...other } = useAuthQuery(
    ['myTrips', boatId, states],
    () => accountApi.domesticV2MeTripsGet(undefined, undefined, undefined, states, boatId),
    {
      enabled: isEnabled,
    }
  );

  return { myTrips: Array.isArray(myTrips) ? myTrips : [myTrips], ...other };
};

export const useInviteCaptainMutation = () => {
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV1BoatsIdCaptainsInvitePostRequest }) =>
      boatApi.domesticV1BoatsIdCaptainsInvitePost(boatId, body),
    {
      onError: (error: any) => {
        enqueueSnackbar(
          error?.response?.data?.error_message ??
            'There was a problem sending the captain Invitation. Please contact info@boatsetter.com',
          { variant: 'error', autoHideDuration: 5000 }
        );
      },
    }
  );
};

export const useSimilarBoatsQuery = (boatId: string) => {
  const similarBoatsQuery = useQuery(
    ['similarBoats', boatId],
    () =>
      boatApi.domesticV2BoatsBoatIdSimilarBoatsGet(boatId) as Promise<
        AxiosResponse<
          {
            data: Boat[];
          },
          any
        >
      >,
    {
      enabled: !!boatId,
      retry: 0,
    }
  );

  const shouldFetchMiamiBoats =
    (similarBoatsQuery.data?.data?.data?.length === 0 || similarBoatsQuery.isError) && similarBoatsQuery.isStale;

  // this is a fallback if API for similar boats doesn't return anything
  const miamiBoatsQuery = useQuery(
    ['miamiBoats'],
    () =>
      searchApi.domesticV2SearchGet(
        null,
        25.7616798,
        -80.1917902,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        'recommended',
        null,
        3
      ),
    {
      enabled: shouldFetchMiamiBoats,
    }
  );

  return shouldFetchMiamiBoats ? miamiBoatsQuery : similarBoatsQuery;
};

export const useIncrementPageViewMutation = () =>
  useMutation((boatId: string) => boatApi.domesticV2BoatsIdIncrementPageViewPost(boatId));

export const useBoatDetails = (boatId: string, isEnabled = true) => {
  const router = useRouter();

  const { data: boatDetails = {}, ...other } = useQuery(
    ['boatDetail', boatId, router.query.package_type || null],
    () =>
      boatApi
        .domesticV2BoatsIdGet(boatId, {
          params: {
            package_type: router.query.package_type,
          },
        })
        .then((response) => response.data),
    {
      keepPreviousData: true,
      retry: 0,
      enabled: isEnabled && !!boatId,
      onError: (error: AxiosError<any, any>) => {
        if (publicRuntimeConfig.BS_ENV !== 'production') {
          if (error.request.status === 410) {
            return getDeletedBoatRedirectBody(error.response.data);
          }
          if (error.request.status === 404) {
            return router.push('/404');
          }
        }
      },
    }
  );

  return { boatDetails, ...other };
};

export const useBoatPackages = (boatId: string, enabled = true) => {
  const { data: { data: boatPackages } = {}, ...rest } = useQuery(
    ['boatPackages', boatId],
    () => boatApi.domesticV2BoatsBoatIdPackagesGet(boatId),
    {
      // fetch only on component render
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: !!boatId && enabled,
    }
  );

  return {
    insuranceType: (boatPackages || [])?.find((pac) => pac.available === true)?.insurance_type,
    boatPackages,
    ...rest,
  };
};

export const useAvailablePackages = (boatId: string, enabled = true) => {
  const { data: { data: availablePackages } = {}, ...rest } = useQuery(
    ['availablePackages', boatId],
    () => boatApi.domesticV3BoatsBoatIdAvailablePackagesGet(boatId),
    {
      enabled: !!boatId && enabled,
    }
  );

  return {
    availablePackages,
    ...rest,
  };
};

type BoatFavoriteBody = {
  id: string;
};

export const useAddRemoveFavoriteBoatMutations = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useLazySnackbar();

  const addFavorite = useMutation(
    (body: BoatFavoriteBody) =>
      axios.post('/domestic/v1/favorites', body, {
        headers: API_CLIENT_HEADERS,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['favoriteBoats']);
        enqueueSnackbar('Boat added to favorites.', {
          variant: 'success',
          autoHideDuration: 5000,
        });
      },
    }
  );
  const removeFavorite = useMutation(
    (id: string) =>
      axios.delete(`/domestic/v1/me/favorites/${id}`, {
        headers: API_CLIENT_HEADERS,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['favoriteBoats']);
        enqueueSnackbar('Boat removed from favorites.', {
          variant: 'success',
          autoHideDuration: 5000,
        });
      },
    }
  );

  return {
    addFavorite,
    removeFavorite,
  };
};

export const useBoatDatePricing = (boatId: string, date: string) => {
  const { data: { data: boatDatePricing } = {}, ...other } = useQuery(
    ['boatDatePricing', boatId, date],
    () => boatApi.domesticV2BoatsIdDatePricingDetailsGet(boatId, date),
    {
      enabled: !!date,
      keepPreviousData: true,
    }
  );
  return { boatDatePricing, ...other };
};

// TODO could be extracted to higher level
export const useFavoriteBoat = (boatId: string) => {
  const { isAuthenticated } = useUserDetails();
  const [isBoatFavorite, setIsBoatFavorite] = useState(false);
  const router = useRouter();
  const eventIntakeTrack = useTrackEvent();
  const [galacticaSession] = useLocalStorage('galactica_session', '');

  const { addFavorite, removeFavorite } = useAddRemoveFavoriteBoatMutations();

  const { data: { data: favoredBoats = [] } = {}, ...other } = useQuery(['favoriteBoats'], favoritesBoatsApiCall, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    onSuccess: (data: any = {}) => {
      const { data: boats = [] } = data;
      const favoriteBoats = boats.map((boat) => boat.boat_public_id);
      setIsBoatFavorite(favoriteBoats.includes(boatId));
    },
  });

  const addAfterAuthAction = useSetAtom(writeAfterAuthActionAtom);

  const addToFavorites = (e) => {
    e?.preventDefault();
    eventIntakeTrack(
      'UserFavouredBoat',
      {
        anonymousId: Cookies.get('uuid') || '',
        sessionId: galacticaSession || '',
        appScreen: router.pathname,
      },
      {
        boatId,
      }
    );

    if (!isAuthenticated) {
      router.push({
        pathname: boatsetterPaths.logIn,
        query: {
          redirect_to: router.asPath,
        },
      });
      addAfterAuthAction({
        type: AfterAuthActionType.ADD_FAVORITE_BOAT,
        payload: boatId,
        action: () => {
          addFavorite.mutateAsync({
            id: boatId,
          });
        },
      });
    } else if (isBoatFavorite) {
      removeFavorite.mutateAsync(boatId);
      setIsBoatFavorite(false);
    } else {
      addFavorite.mutateAsync({
        id: boatId,
      });
      setIsBoatFavorite(true);
    }
  };

  return { isBoatFavorite, addToFavorites, favoredBoats, ...other };
};

export const useInfiniteCaptains = (boatId, isCaptainPackage: boolean, captainNetworkEnabled: boolean) => {
  const { data: { pages = [] } = {}, ...other } = useInfiniteQuery(
    'boatCaptains',
    ({ pageParam = 1 }) =>
      boatApi.domesticV2BoatsIdCaptainsGet(boatId, undefined, {
        params: {
          per_page: 8,
          page: pageParam,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const compiled = (allPages || []).reduce((prev, current) => [...prev, ...current.data], []);

        return compiled.length !== parseInt(lastPage.headers['x-total'], 10) ? allPages.length + 1 : undefined;
      },
      enabled: isCaptainPackage && captainNetworkEnabled,
    }
  );
  return { pages, ...other };
};

export const useInfiniteBoatReviews = (boatId) => {
  const { data: { pages = [] } = {}, ...other } = useInfiniteQuery(
    'boatReviews',
    ({ pageParam = 1 }) =>
      boatApi.domesticV2BoatsBoatIdReviewsGet(boatId, {
        params: { per_page: 10, page: pageParam },
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const compiled = (allPages || []).reduce((prev, current) => [...prev, ...current.data], []);

        return compiled.length !== parseInt(lastPage.headers['x-total'], 10) ? allPages.length + 1 : undefined;
      },
    }
  );

  const total = pages.length > 0 ? parseInt(pages[0].headers['x-total'], 10) : -1;

  return { pages, reviewsTotal: total, ...other };
};

export const useGetBoatAvailableInsurancesQuery = (boatId: string) => {
  const { data: { data: boatAvailableInsurances } = {}, ...rest } = useQuery(
    ['boatAvailableInsurances', boatId],
    () => boatApi.domesticV2BoatsBoatIdInsurancesGet(boatId),
    {
      enabled: !!boatId,
    }
  );

  return {
    boatAvailableInsurances,
    ...rest,
  };
};
