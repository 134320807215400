import round from 'lodash/round';
import Dinero from 'dinero.js';

const USD = 'USD';

const CURRENCY_SYMBOLS = {
  [USD]: '$',
};

export const centsToDollars = (cents: number): number => cents / 100;
export const noCents = (cents: number): number => round(centsToDollars(cents));

export const dollarsToCents = (dollars: number): number => dollars * 100;

export const dollarsFromCents = (cents: number): string => centsToDollars(cents).toFixed(2);

export const dollarsWithCents = (dollars: number): string => dollars.toFixed(2);

// export const dollarsFromCentsWithSymbol = (cents: number, currency: string = USD): string => (
//   `${CURRENCY_SYMBOLS[currency]}${dollarsFromCents(cents)}`
// );

export const dollarsWithSymbol = (cents: number, currency: string = USD): string =>
  `${CURRENCY_SYMBOLS[currency]}${dollarsWithCents(cents)}`;

export const dollarsWithCentsWithSymbol = (amount: number, currency: string = USD): string =>
  amount && typeof amount === 'number' ? Dinero({ amount, currency }).toFormat('$0,0.00') : '$0.00';
export const dollarsNoCentsWithSymbol = (amount: number, currency: string = USD): string =>
  amount && typeof amount === 'number' ? Dinero({ amount, currency }).toFormat('$0,0') : '$0';

export function formatNumber(n) {
  // format number 1000000 to 1,234,567
  const val = n.toString();
  return val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatCurrency(target) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  let input_val = target.value;

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // original length
  const original_len = input_val.length;

  // initial caret position
  let caret_pos = target.selectionStart;

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    let left_side = input_val.substring(0, decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // join number by .
    input_val = `${left_side}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = `${input_val}`;
  }

  // send updated string to input
  // eslint-disable-next-line no-param-reassign
  target.value = input_val;

  // put caret back in the right position
  const updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  target.setSelectionRange(caret_pos, caret_pos);
}
