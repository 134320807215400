import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosConfig, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { User } from 'swagger/models';
import { useSetAtom } from 'jotai';
import { failedLoginAtom } from 'auth/userStore';
import { API_CLIENT_HEADERS } from 'api/OpenApiConfiguration';
import { isEmpty, isNull } from 'lodash';
import AxiosConfig from 'api/AxiosConfig';
import { NextRouter, useRouter } from 'next/router';
import { removeNotTrackingParams } from 'helpers/urlQuery';
import { useLocalStorage } from 'beautiful-react-hooks';

const FIVE_MIN = 5 * 60 * 1000;

export const buildGetPersonalProfileRequestConfig = (router: NextRouter): AxiosRequestConfig => {
  const config: AxiosRequestConfig = { headers: API_CLIENT_HEADERS };
  const analyticsParams = removeNotTrackingParams(router.query);

  if (router.isReady && !isEmpty(analyticsParams)) {
    config.params = analyticsParams;
  }

  return config;
};

const axios = axiosConfig.create(AxiosConfig);

interface IDomesticMeSession {
  galactica_session: string;
}

export const useUserDetails = () => {
  const setFailedLogin = useSetAtom(failedLoginAtom);
  const router = useRouter();

  const {
    data: { data: userDetails = {} } = {},
    isLoading,
    ...rest
  } = useQuery<AxiosResponse<User>>(
    ['userDetails', router.isReady],
    () => axios.get('/domestic/v1/me', buildGetPersonalProfileRequestConfig(router)),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      staleTime: FIVE_MIN,
      onError: () => {
        setFailedLogin(true);
      },
      onSuccess: async (payload) => {
        setFailedLogin(false);

        if (payload.data.firebase_token) {
          const result = await import('stacks/messaging/Firebase');
          result.initFirebase();
          import('auth/firebase').then((e) => e.authFirebase(payload.data.firebase_token));
        }
      },
    }
  );

  const isAuthenticated = !isEmpty(userDetails);
  const [galacticaSession, setGalacticaSession] = useLocalStorage('galactica_session', '');
  const [galacticaSessionUpdatedAt, setGalacticaSessionUpdatedAt] = useLocalStorage('galactica_session_updated_at', '');

  const isLastSessionFetchNotRelevant =
    isNull(galacticaSessionUpdatedAt) || galacticaSessionUpdatedAt < new Date().getTime() - FIVE_MIN;
  if (!isAuthenticated && !isEmpty(galacticaSession) && isLastSessionFetchNotRelevant) {
    setGalacticaSession('');
  }

  useQuery<AxiosResponse<IDomesticMeSession>>(
    ['galacticaSessionOnEmpty', isAuthenticated, galacticaSession, isLastSessionFetchNotRelevant],
    () =>
      axios.post(`/domestic/v2/me/session`, undefined, {
        headers: {
          ...API_CLIENT_HEADERS,
        },
      }),
    {
      enabled: isEmpty(galacticaSession) && isAuthenticated && isLastSessionFetchNotRelevant,
      staleTime: FIVE_MIN,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      onError: () => {
        setGalacticaSession('');
        setGalacticaSessionUpdatedAt(new Date().getTime());
      },
      onSuccess: async (payload) => {
        setGalacticaSession(payload.data.galactica_session);
        setGalacticaSessionUpdatedAt(new Date().getTime());
      },
    }
  );

  return {
    userId: userDetails?.id,
    userDetails,
    isAuthenticated,
    isLoading,
    ...rest,
  };
};

export const useUpdateUserMutation = (invalidate = true) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: User & { terms_of_service?: boolean; tcpa_accepted?: boolean }) =>
      axios.patch('/domestic/v1/me', body, {
        headers: API_CLIENT_HEADERS,
      }),
    {
      onSuccess: async () => {
        if (invalidate) await queryClient.invalidateQueries('userDetails');
      },
    }
  );
};
