import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { trackEvent } from 'utils/tracking/trackEvent';
import { useUserDetails } from 'auth/mutations/userHooks';
import { v4 as uuidv4 } from 'uuid';
import dynamic from 'next/dynamic';
import { useLocalStorage } from 'beautiful-react-hooks';
import { analyticsConnector, trackWebSessionInitialized } from 'hooks/useAnalyticsTrack';

const OnlyAuthProvider = dynamic(() => import('./OnlyAuthProvider'), { ssr: false });

const AppProvider = ({ children }) => {
  const { userDetails, isAuthenticated } = useUserDetails();
  const [galacticaSession] = useLocalStorage('galactica_session', '');

  useEffect(() => {
    if (!Cookies.get('uuid')) {
      Cookies.set('uuid', uuidv4());

      trackWebSessionInitialized(userDetails.id, {
        anonymousId: Cookies.get('uuid') || '',
        sessionId: galacticaSession || '',
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      analyticsConnector.forceFlush();
    });
  }, []);

  useEffect(() => {
    const listener = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    listener();

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  const [fired, setFired] = useState(false);

  const pushAfterLoadGTMEvent = () => {
    if (fired) return;

    trackEvent({
      event: 'afterLoad',
    });

    setFired(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', pushAfterLoadGTMEvent, { once: true });
    window.addEventListener('keydown', pushAfterLoadGTMEvent, { once: true });
    window.addEventListener('mouseover', pushAfterLoadGTMEvent, { once: true });

    return () => {
      window.removeEventListener('scroll', pushAfterLoadGTMEvent);
      window.removeEventListener('keydown', pushAfterLoadGTMEvent);
      window.removeEventListener('mouseover', pushAfterLoadGTMEvent);
    };
  }, [fired]);

  return (
    <>
      {children}
      {isAuthenticated && <OnlyAuthProvider />}
    </>
  );
};

export default AppProvider;
