import boatsetterPaths from 'utils/boatsetterPaths';

export const getDeletedBoatRedirectBody = (
  data: {
    location?: {
      city?: string;
      country_code?: string;
    };
  } = {
    location: {},
  }
) => {
  const { location } = data;

  if (!location?.city || !location?.country_code) {
    return {
      redirect: {
        destination: `${boatsetterPaths.search}?action=search&near=Fort%20Lauderdale,%20FL,%20USA&redirected_from_pdp=true`,
        permanent: true,
      },
    };
  }

  const encodedLocation = encodeURIComponent(`${location.city}, ${location.country_code}`);

  return {
    redirect: {
      destination: `${boatsetterPaths.search}?action=search&near=${encodedLocation}&redirected_from_pdp=true`,
      permanent: true,
    },
  };
};
