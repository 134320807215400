import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import isNaN from 'lodash/isNaN';
import crypto from 'crypto';

type Constants<Type> = {
  [Property in Type as
    | `${Capitalize<string & Property>}`
    | `${Capitalize<string & Property>}_START`
    | `${Capitalize<string & Property>}_COMPLETED`
    | `${Capitalize<string & Property>}_FAILED`]: string;
};

export function createConstants<E extends string>(constants: E[]): Constants<E> {
  const states = ['START', 'COMPLETED', 'FAILED'];
  // @ts-ignore
  return constants.reduce((constantsToExport, constant) => {
    const stateConstants = states.reduce(
      (iteratedConstant, state) => {
        const constantWithState = `${constant}_${state}`;

        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        iteratedConstant[constantWithState] = constantWithState;
        return iteratedConstant;
      },
      {
        [constant]: constant,
      }
    );
    return Object.assign(constantsToExport, stateConstants);
  }, {});
}

export const notEmpty = (value: string) => {
  const emptyObjectLike = isObjectLike(value) && isEmpty(value);
  const trimmedEmptyString = isString(value) && value.trim().length === 0;
  return value === undefined || value === null || emptyObjectLike || trimmedEmptyString ? 'Required Field' : undefined;
};

export const valueEmpty = (value: string | Record<string, any> | number) => {
  const emptyObjectLike = isObjectLike(value) && isEmpty(value);
  const trimmedEmptyString = isString(value) && (value as string).trim().length === 0;
  const emptyWhiteSpace = /^\s*$/.test(value as string);
  return !!(value === undefined || value === null || emptyObjectLike || trimmedEmptyString || emptyWhiteSpace);
};

export const calculateAge = ({ year, month, day }) => {
  const dateString = `${year}-${month}-${day}`;
  const birthday = new Date(dateString);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return ageDate.getFullYear() - 1970;
};

export const isError = (number) => parseInt(`${number}`[0], 10) === 4 || parseInt(`${number}`[0], 10) === 5;

export const constructBookingQueryString = (obj = {}): string =>
  Object.keys(obj)
    .filter((key) => !key.includes('option_'))
    .map((key) => `booking[${encodeURIComponent(snakeCase(key))}]=${encodeURIComponent(obj[key])}`)
    .join('&');

export const constructScreeningQuestionsQueryString = (obj = {}): string =>
  Object.keys(obj)
    .map((key) => `[${encodeURIComponent(snakeCase(key))}]=${encodeURIComponent(obj[key])}`)
    .join('&');

export const queryToString = (obj = {}): string =>
  Object.entries(obj)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

export const checkDateValue = (str, max) => {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    // eslint-disable-next-line no-param-reassign
    str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? `0${num}` : num.toString();
  }
  return str;
};

export const hasProfilePic = (picture) => {
  if (!picture || !picture.url || picture.url === 'default_profile_pic.png' || picture.url.includes('ui-avatars')) {
    return false;
  }
  if (picture && picture.url && picture.url.length) {
    return true;
  }
  return false;
};

export const defaultPic = (first_name) => {
  let initial = '';
  if (first_name) {
    initial = first_name.charAt(0);
  }
  return `https://ui-avatars.com/api/&name=${initial}&background=F4F8F9&color=8E9697&size=100&length=1&font-size=0.6&bold=true`;
};

export const boatRequiresCaptain = (length: number, insuranceType: string) => length > 40 && insuranceType === 'p2p';

export const jsUcfirst = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const renderLabel = (label) => {
  const labelFormat = jsUcfirst(label).replace(/\/[a-z]/g, (match) => match.replace('/', ' / \n').toUpperCase());
  const upperCaseAcronyms = labelFormat.replace(/(?:Gps|Vhf|aux)/g, (match) => match.toUpperCase());
  if (label.toLowerCase() === 'wifi') {
    return 'WiFi';
  }
  return upperCaseAcronyms.replace(/(?:'[a-z]|’[a-z])/g, (match) => `${match} \n`);
};

export const boatMakeAndModel = (boatInfo) => {
  const { make, model, make_and_model } = boatInfo;

  let makeModel = 'Boat';

  if (!valueEmpty(make) && !valueEmpty(model)) {
    makeModel = `${make} ${model}`;
  } else if (!valueEmpty(make_and_model)) {
    makeModel = make_and_model;
  }
  return makeModel;
};

export const getAsString = (value: string | string[]): string => (Array.isArray(value) ? value[0] : value);

export const getAsNumber = (value: string | string[], defaultValue?: number): number => {
  const result = +getAsString(value);
  if (defaultValue) {
    return Number.isNaN(result) ? defaultValue : result;
  }
  return result;
};

export const getAsArray = <T = unknown>(value: T | T[]): Array<T> => (Array.isArray(value) ? value : [value]);

export const sha256HashString = (string: string): string | undefined => {
  // The hash generated from an empty string isn't readily identifiable as empty,
  // so this makes that issue apparent. '' or undefined should be apparent.
  if (!string) return string;

  const hash = crypto.createHash('sha256');
  hash.update(string);
  return hash.digest('hex');
};
