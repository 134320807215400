import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { boatMakeAndModel } from '../../../helpers';
import { centsToDollars, dollarsNoCentsWithSymbol } from '../../../helpers/currency';
import { RENTER_APP_ID, getItunesAppUniversalLinkMetaTag } from '../../../helpers/templateHelpers';
import SEO from '../../SEO';
import { useBoatDetails, useInfiniteBoatReviews } from './hooks';

const { BS_ENV, RAILS_URL } = getConfig().publicRuntimeConfig;

const MetaInfo = ({ boatId }: { boatId: string }) => {
  const router = useRouter();
  const { boatDetails } = useBoatDetails(boatId);
  const { pages: reviews } = useInfiniteBoatReviews(boatId);

  const filteredBoatMakeAndModel = boatMakeAndModel(boatDetails).replace(/Other/gi, 'Boat');
  const boatLocation = `${boatDetails?.location?.city}, ${boatDetails?.location?.state}`;

  const trimmedDescription = (descLength) =>
    boatDetails.listing_description?.length > descLength
      ? `${boatDetails.listing_description?.substring(0, descLength)}...`
      : boatDetails.listing_description;

  const active = boatDetails.activated;

  const preview = router.asPath.includes('preview/');
  const canonicalTag = `${RAILS_URL}/boats/${boatId}`;

  const title = `Rent a ${filteredBoatMakeAndModel} in ${boatLocation} on Boatsetter`;
  const description = `Rent for ${dollarsNoCentsWithSymbol(
    boatDetails?.cheapest_package?.price ?? boatDetails.primary_rate
  )}. ${trimmedDescription(130)}`;

  const flatReviews = reviews.map(({ data }) => data).flat();

  return (
    <>
      <SEO
        title={title}
        description={description}
        ogDescription={description}
        ogImage={boatDetails?.best_photo?.standard?.url}
        noIndex={boatDetails?.state === 'pending'}
      />
      <Head>
        {(BS_ENV === 'staging' || BS_ENV === 'production') && active && !preview && (
          <link rel="canonical" href={canonicalTag} />
        )}
        {getItunesAppUniversalLinkMetaTag(router.asPath, RENTER_APP_ID)}
        {(preview || !active) && <meta name="robots" content="noindex" />}
      </Head>

      <div itemType="http://schema.org/Product" itemScope>
        <meta itemProp="name" content={boatDetails.listing_tagline} />
        {boatDetails?.best_photo?.standard && <link itemProp="image" href={boatDetails?.best_photo?.standard?.url} />}
        {(boatDetails?.photos || []).map((photo) => (
          <link key={photo.standard.url} itemProp="image" href={photo.standard.url} />
        ))}
        <meta itemProp="description" content={boatDetails.listing_description} />
        {!!boatDetails.review_count && !!boatDetails.rating && (
          <div itemProp="aggregateRating" itemType="http://schema.org/AggregateRating" itemScope>
            <meta itemProp="reviewCount" content={String(boatDetails.review_count)} />
            <meta itemProp="ratingValue" content={String(boatDetails.rating)} />
          </div>
        )}
        <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
          <link itemProp="url" href={canonicalTag} />
          <meta itemProp="availability" itemType="https://schema.org/InStock" />
          <meta itemProp="priceCurrency" content="USD" />
          <meta
            itemProp="price"
            content={`${centsToDollars(boatDetails?.cheapest_package?.price ?? boatDetails.primary_rate)}`}
          />
        </div>
        {!!boatDetails.review_count &&
          flatReviews?.length > 0 &&
          flatReviews?.map((review) => (
            <div key={review.id} itemProp="review" itemType="http://schema.org/Review" itemScope>
              <meta itemProp="datePublished" content={review?.created_at} />
              <meta itemProp="reviewBody" content={review?.text} />
              <div itemProp="author" itemType="http://schema.org/Person" itemScope>
                <meta itemProp="name" content={`${review?.author?.first_name} ${review?.author?.last_name}`} />
              </div>
              <div itemProp="itemReviewed" itemScope itemType="http://schema.org/Thing">
                <meta itemProp="name" content="Boat" />
              </div>
              <div itemProp="reviewRating" itemType="http://schema.org/Rating" itemScope>
                <meta itemProp="ratingValue" content={String(review?.rating)} />
                <meta itemProp="bestRating" content="5" />
                <meta itemProp="worstRating" content="1" />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default MetaInfo;
