import getConfig from 'next/config';
import { API_CLIENT_HEADERS } from './OpenApiConfiguration';

const { publicRuntimeConfig } = getConfig();

export default {
  baseURL: publicRuntimeConfig.API_HOST,
  headers: {
    ...API_CLIENT_HEADERS,
  },
};
