import { Configuration } from 'swagger/configuration';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const API_CLIENT_HEADERS = {
  'x-api-client': 'js-api-lib',
  'x-appversion': 1,
  'x-version': 1,
  'x-client': 'AUTH_SPA',
  authorization: 'Basic Y2FwdGFpbjpwaWNhcmQ=',
};

export default new Configuration({
  basePath: publicRuntimeConfig.API_HOST,
  baseOptions: {
    headers: {
      ...API_CLIENT_HEADERS,
    },
  },
});
